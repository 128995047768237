import { BasePaymentGateway } from './base-payment.gateway';
import { PAYMENT_CONSTANTS } from '../../constants/payment.constants';
import HttpApis from '../HttpApis';

export class PayPalGateway extends BasePaymentGateway {
  async loadScript() {
    const PAYPAL_CLIENT_ID = localStorage.getItem('public_key'); // Fetch from localStorage
  
  if (!PAYPAL_CLIENT_ID) {
    console.error('PayPal public key (client ID) is not found in localStorage.');
    return Promise.resolve(false); // Fail gracefully if no public key is found
  }

    return new Promise((resolve) => {
      if (document.getElementById('paypal-script')) {
        resolve(true);
        return;
      }

      const script = document.createElement('script');
      script.id = 'paypal-script';
      script.src = `${PAYMENT_CONSTANTS.GATEWAY_SCRIPTS.paypal}?client-id=${PAYPAL_CLIENT_ID}&currency=USD&components=buttons`;
      script.async = true;
      
      script.onload = () => {
        console.log('PayPal script loaded successfully');
        resolve(true);
      };
      
      script.onerror = () => {
        console.error('PayPal script failed to load');
        resolve(false);
      };
      
      document.body.appendChild(script);
    });
  }

  async renderButtons(containerId, options) {
    try {
      const container = document.getElementById(containerId);
      if (!container) {
        throw new Error(`Container ${containerId} not found`);
      }

      container.innerHTML = '';

      const buttons = await window.paypal.Buttons({
        // style: {
        //   layout: 'vertical',
        //   color: 'blue',
        //   shape: 'rect',
        //   label: 'subscribe'
        // },
        createOrder: async () => {
          try {
            const orderResponse = await options.createOrder();
            return orderResponse;
          } catch (error) {
            console.error('Error creating order:', error);
            throw error;
          }
        },
        onApprove: async (data, actions) => {
          try {
            await options.onApprove(data, actions);
          } catch (error) {
            console.error('Error on approve:', error);
            options.onError(error);
          }
        },
        onError: (err) => {
          console.error('PayPal button error:', err);
          options.onError(err);
        },
        onCancel: () => {
          console.log('Payment cancelled');
          options.onCancel();
        }
      });

      const isEligible = await buttons.isEligible();
      if (!isEligible) {
        throw new Error('PayPal Buttons are not eligible');
      }

      await buttons.render(container);
      return true;
    } catch (error) {
      console.error('Error rendering PayPal buttons:', error);
      throw error;
    }
  }

  async verifyPayment(data) {
    try {
      const verificationResponse = await HttpApis.verifyPayment({
        payment_id: data.paymentID,
        order_id: data.orderID,
        gateway: 'paypal',
        payment_type: data.payment_type
      });

      return {
        success: verificationResponse.status,
        message: verificationResponse.message || 'Payment processed successfully'
      };
    } catch (error) {
      console.error('PayPal verification failed:', error);
      return {
        success: false,
        message: 'Payment verification failed'
      };
    }
  }
}

