import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useEpg } from "planby";
import { theme } from '../components/EpgGuide/theme';
import HttpApis from "../services/HttpApis";

export function useApp() {
  const [channels, setChannels] = useState([]);
  const [epg, setEpg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const channelsData = useMemo(() => channels, [channels]);
  const epgData = useMemo(() => epg, [epg]);

  const today = new Date();
  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}T00:00:00`;
  };

  const startDate = formatDate(today);
  const endDate = formatDate(tomorrow);

  const { getEpgProps, getLayoutProps } = useEpg({
    channels: channelsData,
    epg: epgData,
    dayWidth: 7200,
    sidebarWidth: 100,
    itemHeight: 80,
    isSidebar: true,
    isTimeline: true,
    isLine: true,
    startDate: startDate,
    endDate: endDate,
    isBaseTimeFormat: true,
    theme
  });

  const transformChannel = (channel) => ({
    uuid: channel.id,
    type: "channel",
    title: channel.name,
    country: "USA",
    provider: 7427,
    logo: channel.logo,
    year: 2024
  });

  const transformProgramData = (program, playlistUrl) => {
    return {
      tags: program.video?.title || "No Title",
      id: program.id,
      description: program.description || "desc",
      title: program.video?.title || "No Title",
      isYesterday: false,
      since: program.start_datetime,
      till: program.end_datetime,
      channelUuid: program.channel_id,
      image: program.video?.thumbnail || "",
      playlist_url: playlistUrl || "Playlist URL not present",

      country: "USA",
      Year: "2021–",
      Rated: "TV-14",
      Released: "29 Dec 2021",
      Runtime: "N/A",
      Genre: "Action, Adventure, Sci-Fi",
      Director: "N/A",
      Writer: "Jon Favreau",
      Actors: "Temuera Morrison, Ming-Na Wen, Matt Berry",
      Language: "English",
      Country: "United States",
      Awards: "N/A",
      Metascore: "N/A",
      imdbRating: "8.0",
      imdbVotes: "20,147",
      imdbID: "tt13668894",
      Type: "series",
      totalSeasons: "1",
      Response: "True",
      Ratings: [
        {
          Source: "Internet Movie Database",
          Value: "8.0/10"
        }
      ],
      rating: 3
    };
  };

  const handleFetchResources = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await HttpApis.getChanelsWithScheduls();

      // console.log("API Response:", response.data); // Debugging statement

      const channels = response.data.data.map(transformChannel);
      const epg = response.data.data.flatMap(channel => 
        channel.video_schedules.map(schedule => {
          const transformedSchedule = transformProgramData(schedule, channel.playlist_url);
          // console.log("Transformed Schedule:", transformedSchedule); // Debugging statement
          return transformedSchedule;
        })
      );

      setChannels(channels);
      setEpg(epg);
    } catch (error) {
      console.error('Error fetching channels and schedules:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetchResources();
  }, [handleFetchResources]);

  return { getEpgProps, getLayoutProps, isLoading };
}
