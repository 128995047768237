import { BasePaymentGateway } from './base-payment.gateway';
import { PAYMENT_CONSTANTS } from '../../constants/payment.constants';
import HttpApis from '../HttpApis';

export class RazorpayGateway extends BasePaymentGateway {
  async loadScript() {
    return new Promise((resolve) => {
      if (document.getElementById('razorpay-script')) {
        resolve(true);
        return;
      }

      const script = document.createElement('script');
      script.id = 'razorpay-script';
      script.src = PAYMENT_CONSTANTS.GATEWAY_SCRIPTS.razorpay;
      script.async = true;
      
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      
      document.body.appendChild(script);
    });
  }

  async initializePayment(options) {
    const razorpayOptions = {
      key: options.publicKey,
      amount: parseFloat(options.amount) * 100,
      currency: options.currency,
      name: PAYMENT_CONSTANTS.MERCHANT_NAME,
      description: options.description,
      order_id: options.orderId,
      handler: options.onSuccess,
      prefill: options.userInfo,
      notes: options.metadata,
      theme: { color: PAYMENT_CONSTANTS.THEME_COLOR }
    };

    const razorpay = new window.Razorpay(razorpayOptions);
    razorpay.on('payment.failed', options.onError);
    razorpay.open();
  }

  async verifyPayment(data) {
    try {
      const verificationResponse = await HttpApis.verifyPayment({
        ...data,
        gateway: 'razorpay'
      });

      return {
        success: verificationResponse.status,
        message: verificationResponse.message || 'Payment processed successfully'
      };
    } catch (error) {
      console.error('Razorpay verification failed:', error);
      return {
        success: false,
        message: 'Payment verification failed'
      };
    }
  }
}
