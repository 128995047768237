import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpApis from "../services/HttpApis";
import { useDispatch } from "react-redux";
import { menuFailure, menuStart, menuSuccess } from "../redux/slices/menuSlice";
import { useLocation } from "react-router-dom";


const useSectionHook = () => {
  const [selectedSectionID, setSelectedSectionID] = useState(null);
  const [sectionData, setSectionData] = useState([]);
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchMenuList = async () => {
      try {
        dispatch(menuStart());
        const response = await HttpApis.getMenuList();
        // response.data.data.push({
        //   name: "Guide",
        //   icon: "https://admin.vizzytv.com/icons/play_circle_24dp_000000_FILL0_wght400_GRAD0_opsz24.png",
        //   type: "section",
        //   section_id: 100,
        // });
        dispatch(menuSuccess(response));
        handleSectionClick(response.data.data[0])
        return response;
      } catch (error) {
        dispatch(menuFailure(error.message));
      }
    };
    fetchMenuList();
  }, [dispatch]);

  const handleSectionClick = async (data, pathname) => {
    setSelectedSectionID(data); // Update selectedSectionID
    try {
      const response = await HttpApis.getSection(data.section_id);
      if (response.status === 200) {
        setSectionData(response.data.data); // Update sectionData
        if (pathname === '/') navigate('/')
        else navigate(location.pathname)
      } else {

        setSectionData([]);
      }
    } catch (error) {
      console.error("Error fetching section data:", error);
      setError(error.message);
      setSectionData([]);
    }
  };

  const handleDetails = async (id, type) => {
    setShowDetails(true);
    console.log(id,type,"idType");
    setError(null);
    try {
      let response;
      if (type === "series") {
        response = await HttpApis.getSeriesList(id);
        console.log("series data" , response.data);
        navigate(`/details/${type}/${id}`);
      } else {
        response = await HttpApis.getMoviesList(id);
        navigate(`/details/${type}/${id}`);
      }
      setDetails(response.data);
      console.log("setrespnse type", response.data);
      setShowDetails(true);
    } catch (error) {
      setError(error.message);
      setDetails(null);
    }
  };

  return {
    handleSectionClick,
    selectedSectionID,
    sectionData,
    handleDetails,
    showDetails,
    details,
    error,
  };
};

export default useSectionHook;