// components/Subscription.jsx
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HttpApis from '../../services/HttpApis';
import usePaymentService from '../../hooks/usePaymentService';
import './Subscription.css';

function Subscription() {
  const [plans, setPlans] = useState([]);
  const authtoken = useSelector((state) => state?.authReducer?.user?.data?.token);
  const navigate = useNavigate();

  const {
    loading,
    error,
    activePaymentMethod,
    handlePayment,
    initializePayPalButtons
  } = usePaymentService();

  useEffect(() => {
    if (authtoken) {
      fetchSubscriptionPlans();
    }
  }, [authtoken]);

  useEffect(() => {
    initializePayPalButtons(plans);
  }, [plans, initializePayPalButtons]);

  const fetchSubscriptionPlans = async () => {
    try {
      const response = await HttpApis.subscriptionPlans({
        headers: {
          'Authorization': `Bearer ${authtoken}`,
          'Content-Type': 'application/json',
        }
      });

      if (response?.status && Array.isArray(response?.data?.data)) {
        setPlans(response.data.data);
      } else {
        throw new Error('Invalid plan data received');
      }
    } catch (err) {
      setPlans([]);
    }
  };

  return (
    <div className="subscription-container">
      <h1>Choose a plan for becoming a PRIME member</h1>

      {activePaymentMethod && (
        <div className="payment-method-info">
          Payment Method: {activePaymentMethod.charAt(0).toUpperCase() + activePaymentMethod.slice(1)}
        </div>
      )}

      {/* {error && <div className="error-message">{error}</div>} */}

      <div className="subscription-plans">
        {plans.map((plan) => (
          <div key={plan.plan_id} className="subscription-plan">
            <div className="planPrice">
              <h3 className="plan-name">{plan.plan_name}</h3>
              <div className="price-container">
                <span className="current-price">
                  {plan.plan_price} {plan.currency_code}
                </span>
              </div>
            </div>

            {authtoken ? (
              activePaymentMethod === 'paypal' ? (
                <div
                  id={`paypal-button-${plan.plan_id}`}
                  className="paypal-button-container"
                />
              ) : (
                <button
                  className="subscribe-button"
                  onClick={() =>
                    handlePayment({
                      purchase_type: 'subscription',
                      product_id: plan.productId,
                    })
                  }
                  disabled={loading}                
                >
                  {loading ? 'Processing...' : 'Subscribe'}
                </button>
              )
            ) : (
              <button className="subscribe-button" onClick={() => navigate('/login')}>
                Login to Subscribe
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Subscription;