import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import useWindowSize from "../../hooks/windowScreenHook";
import Hero from "../Hero/Hero";
import useDetailsHook from "../../hooks/DetailsHook";

// Custom Arrows

const Banner = (props) => {
  const {
    selectedSectionID,
    sectionData,
    handleDetails,
    currentIndex,
    handleSlideChange,
  } = props;
  const [sliders, setSliders] = useState(null);
  const [rows, setRows] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { width } = useWindowSize();
  const [slidercount, setcount] = useState(0);

  const sliderRef = useRef(null);

  useEffect(() => {
    const checkDataType = (data) => {
      if (Array.isArray(data)) {
        data.forEach((el) => {
          if (el && el.type && el.type.toLowerCase() === "carousel") {
            setSliders(el);
            setcount(el.items.length);
            console.log(slidercount, "slidercount");
          } else {
            setRows(el);
          }
        });
      }
    };

    if (sectionData) {
      checkDataType(sectionData);
    }
  }, [sectionData]);

  useEffect(() => {
    if (sliders && sliders.items) {
      const currentItem = sliders.items[activeIndex];
      if (currentItem) {
        // setTitle(currentItem.title);
        // setDescription(currentItem.description);
      }
    }
  }, [activeIndex, sliders]);

  useEffect(() => {
    if (sliderRef.current) {
      // Move to the current index passed in props
      sliderRef.current.slickGoTo(currentIndex);
    }
  }, [currentIndex]);

  if (!selectedSectionID || !sectionData) {
    return <div>No section selected</div>;
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    centerMode: true,
    variableWidth: true,
    mobileFirst: true,
    arrows: true,
  };

  // console.log(currentIndex, "currentIndex");
  return (
    <div>
      {width < 1200
        ? sliders &&
          sliders.items &&
          sliders.items.length > 0 && (
            <div className="col-12">
              <div className="slider-container">
                <Slider
                  {...sliderSettings}
                  ref={sliderRef}
                  beforeChange={handleSlideChange}
                >
                  {sliders.items.map((item, index) => (
                    <div key={index} className="carousel-item">
                      <Hero
                        title={item.title}
                        info="14+ | 2024 | Drama"
                        description={item.description}
                        posterUrl={item.thumbnail_image}
                        data={item.id}
                        details={false}
                        type={item.type}
                        handleDetails={handleDetails}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          )
        : sliders &&
          sliders.items &&
          sliders.items.length > 0 && (
            // <div className="col-12">
            <div className="slider-container">
              <Slider
                {...sliderSettings}
                ref={sliderRef}
                beforeChange={handleSlideChange}
              >
                {sliders.items.map((item, index) => (
                  <div key={index} className="carousel-item">
                    <Hero
                      title={item.title}
                      info={item.duration}
                      description={item.description}
                      posterUrl={item.thumbnail_image}
                      data={item.id}
                      details={false}
                      type={item.type}
                      handleDetails={handleDetails}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            // </div>
          )}
    </div>
  );
};

export default Banner;
