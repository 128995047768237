import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineMail, AiOutlineEdit } from 'react-icons/ai';
import { FaSignOutAlt, FaUser,FaTv } from 'react-icons/fa';
import { RiMoneyPoundCircleLine } from 'react-icons/ri';
import './MyAccount.css';
import Subscription from './Subscription';
import { useNavigate } from 'react-router-dom';


function MyAccount() {
    const loginUser = useSelector((state) => state?.authReducer?.user?.data?.user);
    const [accountDetails, setAccountDetails] = useState(loginUser);
   
    useEffect(() => {
        // Simulating an API call to fetch account details
        // setAccountDetails(loginUser); // Uncomment if account details might change
    }, [loginUser]);
    console.log(accountDetails);
   

    const navigate = useNavigate();

    const ViewSubscription = () => {
        navigate('/subscribe');
      };

    const activateLink = ()=>{
        navigate('/link');
    }
    const visitDevicePage = ()=>{
        console.log("navigate to the visiteDevicePage");
        navigate('/devices');
    }
    return (
        <div className="account-container">
            
            {accountDetails ? (
                <>
                    <div className="account-item">
                        <FaUser  />
                        <p>{accountDetails.name}</p>
                        
                    </div>
                    <div className="account-item">
                        <AiOutlineMail  />
                        <p>{accountDetails.email}</p>
                       
                    </div>
                    <div className="account-item">
                        <RiMoneyPoundCircleLine  />
                        <p>{accountDetails.plan_amount}</p>
                        
                    </div>

                    <div className="account-item" onClick={activateLink}>
                        
                        <FaTv  />
                        <p>Link your device here</p>
                        
                    </div>
                    <div className="account-item" onClick={visitDevicePage}>
                        
                        <FaTv  />
                        <p>Devices</p>
                        
                    </div>

                    {accountDetails.is_premium === false && (
        <button className='tryPremium' onClick={ViewSubscription}>
          Try Premium
        </button>
      )}
                    
                </>
            ) : (
                <p>Loading account details...</p>
            )}
        </div>
    );
}

export default MyAccount;
