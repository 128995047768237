export class BasePaymentGateway {
  async loadScript() {
    throw new Error('loadScript method must be implemented');
  }

  async initializePayment() {
    throw new Error('initializePayment method must be implemented');
  }

  async verifyPayment() {
    throw new Error('verifyPayment method must be implemented');
  }
}
