import React, { useEffect, useRef, useState } from "react";
import * as Styled from "./style";
import { IoPlay } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import HttpApis from "../../services/HttpApis";
import Hls from "hls.js";
import useWindowSize from "../../hooks/windowScreenHook";
import { Spin } from "antd"; // Import Spin for the loader
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// import { BsRewindCircle } from "react-icons/bs";
import { FaBackward } from "react-icons/fa";
import { FaForward } from "react-icons/fa";
import { IoMdVolumeOff } from "react-icons/io";
import { FaPause } from "react-icons/fa6";
import { FaPlay } from "react-icons/fa6";
import { SlSizeFullscreen } from "react-icons/sl";
import { IoVolumeLow } from "react-icons/io5";
import { IoVolumeMedium } from "react-icons/io5";
import { IoVolumeHigh } from "react-icons/io5";
const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [title, setTitle] = useState();
  const [movieTitle, setMovieTitle] = useState();
  const [seriesData, setSeriesData] = useState();
  const [moviesData, setMoviesData] = useState();
  const [videoSource, setVideoSource] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { width } = useWindowSize();
  const params = useParams();
  const navigate = useNavigate();
  const [volume, setVolume] = useState(1);
  const [slug, setSlug] = useState();
  const [episodeSlug, setEpisodeSlug] = useState();
  const [Live, setLive] = useState();
  const showTopNavbar = useRef(null);

  const authtoken = useSelector(
    (state) => state?.authReducer?.user?.data?.token
  );

  const location = useLocation();
  const { videoURL } = location.state || {};
  // console.log("cideo-do-weofwe", location.state.startTime);

  // New state for offline detection
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  // Controls visibility state
  const [controlsVisible, setControlsVisible] = useState(true);

  // Handle inactivity timeout
  const controlsTimeoutRef = useRef(null);

  seriesData?.forEach((item) => {
    item?.episodes?.forEach((episode) => {
      // Ensure both `params.id` and `episode.id` are of the same type
      if (params.id === episode.id) {
        setEpisodeSlug(episode.slug);
      }
    });
  });

  const formatTime = (seconds) => {
    if (isNaN(seconds)) return "00:00:00";
    let hrs = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    let mins = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    let secs = (Math.floor(seconds) % 60).toString().padStart(2, "0");
    if (hrs === "00" && mins === "00") {
      return `${secs}:00`;
    } else if (hrs === "00") {
      return `${mins}:${secs}`;
    } else {
      return `${hrs}:${mins}:${secs}`;
    }
  };

  const handleBaackButton = (type) => {
    if (params.type === "live") navigate("/");
    else {
      console.log("this is the postdata");
      navigate(`/details/${params?.type}/${params?.id}`);
      postData();
    }
  };

  // const postData = async () => {
  //   try {
  //     const currentTime = Math.floor(videoRef?.current?.currentTime);
  //     const content_type = params?.type === "series" ? "episode" : params?.type;
  //     const data = {
  //       content_type: content_type,
  //       content_slug: episodeSlug ? episodeSlug : slug,
  //       progress_seconds: currentTime,
  //     };
  //     console.log(data, "postdata");
  //     const headers = {
  //       Authorization: `Bearer ${authtoken}`,
  //       "Content-Type": "application/json",
  //     };
  //     const response = await HttpApis.postContinueWatching(data, headers);
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const postData = async () => {
    try {
      const currentTime = Math.floor(videoRef?.current?.currentTime); // Current playback time
      const data = {
        content_type: params?.type, // Use the type directly from params
        content_slug:
          params?.type === "series" && episodeSlug
            ? episodeSlug
            : "thecosbyshow1984s01e01pilot", // Always prefer the episode slug for series
        progress_seconds: currentTime, // Playback progress in seconds
      };

      console.log("postData payload:", data); // Debugging payload

      const headers = {
        Authorization: `Bearer ${authtoken}`,
        "Content-Type": "application/json",
      };

      const response = await HttpApis.postContinueWatching(data, headers);
      console.log("API response:", response);

      return response;
    } catch (error) {
      console.error("postData error:", error);
    }
  };

  useEffect(() => {
    // Listen for online/offline events
    const handleOnlineStatus = () => setIsOffline(false);
    const handleOfflineStatus = () => setIsOffline(true);

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOfflineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOfflineStatus);
    };
  }, []);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        let response;
        if (params.type === "series") {
          response = await HttpApis.getSeriesList(params.id);
          setSeriesData(response?.data?.data?.series?.seasons);
          setSlug(response?.data?.data?.series?.slug);
        } else if (params.type === "movie") {
          // console.log("params", params.type);
          response = await HttpApis.getMoviesList(params.id);
          setMovieTitle(response?.data?.data?.movie?.title);
          setDuration(response?.data?.data?.movie?.duration);
          setSlug(response?.data?.data?.movie?.slug);
          setMoviesData(
            response?.data?.data?.movie?.video_detail?.hls_playlist_url
          );
          // console.log(" response?.data?.data?.movie?.video_detail?.hls_playlist_url", response?.data?.data?.movie?.video_detail?.hls_playlist_url);
        } else {
          setLive(videoURL);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchDetails();
  }, [params.id]);

  useEffect(() => {
    if (params.type === "series") {
      const video = seriesData
        ?.map((item) =>
          item?.episodes.map((itemVideo) => {
            if (itemVideo.id == params.id) {
              setTitle(itemVideo?.title);
              setDuration(itemVideo?.duration);
              return itemVideo?.video_detail?.hls_playlist_url;
            }
            return null;
          })
        )
        .flat()
        .find((videoUrl) => videoUrl !== null);
      setVideoSource(video);
    } else if (params.type === "movie") {
      setVideoSource(moviesData);
      // console.log("moviesdata", moviesData);
    } else {
      showTopNavbar.current = true;
      setVideoSource(Live);
      console.log("live", Live);
    }
  }, [
    seriesData,
    moviesData,
    Live,
    params.type,
    params.id,
    params.monitization_type,
  ]);

  useEffect(() => {
    // console.log("videoSourcevideoSource", videoRef.current.canPlayType * ("application/vnd.apple.mpegurl"));
    if (videoSource && Hls.isSupported() && videoRef.current) {
      const hls = new Hls();
      hls.loadSource(videoSource);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {});
      hls.on(Hls.Events.ERROR, function (event, data) {
        console.error("HLS Error:", data);
      });
    } else if (
      videoSource &&
      videoRef.current.canPlayType("application/vnd.apple.mpegurl")
    ) {
      console.log("videoSourcevideoSource", videoSource);
      videoRef.current.src = videoSource;
    }
  }, [videoSource]);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play().catch((error) => {
        console.error("Error playing the video:", error);
      });
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    postData();
  };

  const skipForward = () => {
    if (videoRef.current) {
      const currentTime = videoRef?.current?.currentTime;
      videoRef.current.currentTime = currentTime + 10;
    }
  };

  const skipBackward = () => {
    if (videoRef.current) {
      const currentTime = videoRef?.current?.currentTime;
      videoRef.current.currentTime = Math.max(0, currentTime - 10);
    }
  };

  const toggleMute = () => {
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
      setIsMuted(false);
      videoRef.current.volume = volume;
    } else {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const toggleFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
    setIsFullscreen(true);
  };

  const handleSeek = (e) => {
    const videoElement = videoRef?.current;
    if (videoElement) {
      videoElement.currentTime = e.target.value;
    }
  };
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.onloadedmetadata = () => {
        setDuration(videoElement.duration);
      };
    }
  }, []);

  // useEffect(() => {
  //   const videoElement = videoRef?.current;
  //   if (videoElement) {
  //     const timeUpdateHandler = () => {
  //       setCurrentTime(videoElement?.currentTime);
  //     };
  //     videoElement.addEventListener("timeupdate", timeUpdateHandler);

  //     return () => {
  //       videoElement.removeEventListener("timeupdate", timeUpdateHandler);
  //     };
  //   }
  // }, []);
  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement && location.state.startTime) {
      // Ensure the video is ready before setting the time
      videoElement.onloadedmetadata = () => {
        videoElement.currentTime = location.state.startTime;
      };
    }
  }, [location.state.startTime, videoRef]);

  const getVolumeIcon = () => {
    if (isMuted || volume === 0) {
      // Show mute icon when either muted or volume is 0
      return <IoMdVolumeOff className="iconStyle" />;
    } else if (volume <= 0.33) {
      // Low volume icon
      return <IoVolumeLow className="iconStyle" />;
    } else if (volume <= 0.66) {
      // Medium volume icon
      return <IoVolumeMedium className="iconStyle" />;
    } else {
      // High volume icon
      return <IoVolumeHigh className="iconStyle" />;
    }
  };
  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    videoRef.current.volume = newVolume;
    setVolume(newVolume);

    // Mute the video if voFlume is 0
    if (newVolume == 0) {
      setIsMuted(true);
      videoRef.current.muted = true;
    } else {
      setIsMuted(false);
      videoRef.current.muted = false;
    }
  };
  useEffect(() => {
    const handleSpacebar = (e) => {
      if (e.key === " " || e.code === "Space") {
        togglePlayPause();
      }
    };

    window.addEventListener("keydown", handleSpacebar);

    return () => {
      window.removeEventListener("keydown", handleSpacebar);
    };
  }, []);

  const handleVideoClick = () => {
    togglePlayPause();
  };

  const showNext = formatTime(duration - currentTime);

  useEffect(() => {
    const handleArrowKeys = (e) => {
      if (e.key === "ArrowLeft") {
        skipBackward(); // Skip 10 seconds backward
      } else if (e.key === "ArrowRight") {
        skipForward(); // Skip 10 seconds forward
      }
    };

    window.addEventListener("keydown", handleArrowKeys);

    return () => {
      window.removeEventListener("keydown", handleArrowKeys);
    };
  }, []);

  const resetControlsVisibility = () => {
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    controlsTimeoutRef.current = setTimeout(() => {
      setControlsVisible(false);
    }, 10000);
  };

  const handleMouseEnter = () => {
    setControlsVisible(true);
    resetControlsVisibility();
  };

  const handleMouseLeave = () => {
    resetControlsVisibility();
    setControlsVisible(true);
  };
  // console.log(controlsVisible);

  useEffect(() => {
    // Function to reset the visibility timer on mouse movement
    const handleMouseMovement = () => {
      setControlsVisible(true); // Show controls when mouse moves
      resetControlsVisibility(); // Reset the timeout
    };

    // Attach mousemove event to the entire document
    document.addEventListener("mousemove", handleMouseMovement);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMovement);
    };
  }, []);

  console.log(showTopNavbar);

  return (
    <Styled.VideoContainer>
      {isOffline ? (
        <div className="offline-loader">
          <Spin tip="Loading" size="large" />
        </div>
      ) : (
        <>
          {/* {showTopNavbar.current ? null : (
            <div
              style={{ display: controlsVisible ? "" : "none" }}
              className="header"
            >
              <div
                className="backbutton"
                style={{ border: "1px solid red" }}
                onClick={handleBaackButton}
              >
                <IoMdArrowRoundBack className="backButtonIcon" />
              </div>
              <div className="titleTextplayer ">{movieTitle || title}</div>
            </div>
          )} */}
          {showNext === "20sec" && (
            <div className="episodeBox">
              <button className="episode">
                <IoPlay className="episodeIcon" />
                Next Episode
              </button>
            </div>
          )}
          <div className="video-container" onClick={handleVideoClick}>
            <video
              ref={videoRef}
              className="video-player"
              onEnded={handleVideoEnd}
              width="100%"
              autoPlay
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
              onLoadedMetadata={() => {
                if (location.state?.startTime) {
                  videoRef.current.currentTime = location.state.startTime;
                }
              }}
              onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
            >
              <source src={videoSource} type="application/x-mpegURL" />
              Your browser does not support the video tag.
            </video>
          </div>

          {/* Controls */}
          <div
            style={{ display: controlsVisible ? "" : "none" }}
            className={`controls ${isFullscreen ? "fullscreen" : ""}
            //  ${controlsVisible ? "visible" : "hidden"}
             `}
          >
            <div className="timeBox">
              <div>{formatTime(currentTime)}</div>
              <div>{formatTime(duration)}</div>
            </div>

            <div className="timeline">
              <input
                type="range"
                min="0"
                max={duration || 0}
                value={currentTime}
                step="1"
                onChange={(e) => handleSeek(e)}
                className="seekWidth"
              />
            </div>

            {/* Control Icons */}
            <Styled.IconBox>
              <div className="leftBox">
                <button onClick={togglePlayPause} className="iconcontainer">
                  {isPlaying ? (
                    <FaPause className="iconStyle" />
                  ) : (
                    <FaPlay className="iconStyle" />
                  )}
                </button>
                <button onClick={skipBackward} className="">
                  <FaBackward className="iconStyle" />
                </button>
                <button onClick={skipForward} className="iconcontainer">
                  <FaForward className="iconStyle" />
                </button>
                <div onClick={toggleMute}>{getVolumeIcon()}</div>
                <div className="volumeBox">
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    className="sliderVolume"
                    onChange={handleVolumeChange}
                    value={isMuted ? 0 : volume} // Bind to volume state
                  />
                </div>
              </div>

              {width > 650 ? <div className="titleText"></div> : ""}

              <div className="fullScreenText">
                <button onClick={toggleFullscreen}>
                  <SlSizeFullscreen className="iconStyle" />
                </button>
              </div>
            </Styled.IconBox>
            {width < 650 ? <div></div> : ""}
          </div>
        </>
      )}
    </Styled.VideoContainer>
  );
};

export default VideoPlayer;
