import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MenuItems from "../Navigation/MenuItems"; // Import the menu component
import { useEffect, useState } from "react";

const Layout = ({ listData, handleSectionClick, error }) => {
  const location = useLocation();
  const isVideoPlayerRoute = location.pathname.startsWith("/player");
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  useEffect(() => {
    if (isVideoPlayerRoute) {
      const interval = setInterval(() => {
        setIsMenuVisible(false);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isVideoPlayerRoute]);

  //adding a event listner to listen if mouse moeve
  useEffect(() => {
    const handleMouseMove = () => {
      if (isVideoPlayerRoute) {
        setIsMenuVisible(true);
      }
    };
    if (isVideoPlayerRoute) {
      window.addEventListener("mousemove", handleMouseMove);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isVideoPlayerRoute]);

  const menuOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    // height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 9999,
  };

  const menuItemsStyle = {
    position: "relative",
    zIndex: 10000,
  };
  return (
    <div>
      {error && <div className="error-message">{error}</div>}
      {/* for all other routes except the player */}
      {!isVideoPlayerRoute && (
        <MenuItems
          listData={listData}
          handleSectionClick={handleSectionClick}
          error={error}
        />
      )}
      {/* On the player route, the menu will visible or inivisible in specific time */}
      {isVideoPlayerRoute && isMenuVisible && (
        <div style={menuOverlayStyle}>
          <MenuItems
            listData={listData}
            handleSectionClick={handleSectionClick}
            error={error}
            // style={menuItemsStyle}
          />
        </div>
      )}
      {/* Page-specific content goes here */}
      <Outlet />
    </div>
  );
};

export default Layout;
