import HttpMethod from "./HttpMethod";
import { BASE_BACKEND_URL } from "../api/config";
import { store } from '../redux/store'; // Import the store directly

const RAZORPAY_CONFIG = {
  KEY_ID: 'rzp_test_Z8P2mZx3Mcytag',
  KEY_SECRET: 'U0DGIZXP8G0YdAu0GtaHB1xw'
};

class HttpApis {
  constructor() {
    this.baseUrl = BASE_BACKEND_URL;
  }

  // Helper method to get auth token from persisted Redux store
  getAuthToken() {
    try {
      const state = store.getState();
      return state?.authReducer?.user?.data?.token || null;
    } catch (error) {
      console.error('Error accessing auth token:', error);
      return null;
    }
  }

  // Helper to merge headers with auth token if available
  getHeaders(customHeaders = {}) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...customHeaders
    };

    const authToken = this.getAuthToken();
    if (authToken) {
      headers['Authorization'] = `Bearer ${authToken}`;
    }

    return headers;
  }

  // Generic request methods with automatic header handling and error catching
  async get(endpoint, params = '', customHeaders = {}) {
    try {
      const response = await HttpMethod._get(
        `${this.baseUrl}${endpoint}`,
        params,
        this.getHeaders(customHeaders)
      );
      return response;
    } catch (error) {
      console.error(`GET request failed for ${endpoint}:`, error);
      throw error;
    }
  }

  async post(endpoint, params = {}, customHeaders = {}) {
    try {
      const response = await HttpMethod._post(
        `${this.baseUrl}${endpoint}`,
        params,
        this.getHeaders(customHeaders)
      );
      return response;
    } catch (error) {
      console.error(`POST request failed for ${endpoint}:`, error);
      throw error;
    }
  }

  // Content Related APIs
  async getMenuList(params) {
    return this.get('menu', params);
  }

  async getSection(sectionId) {
    return this.get(`section/${sectionId}`);
  }

  async getSeriesList(id) {
    return this.get(`series/${id}`);
  }

  async getMoviesList(id) {
    return this.get(`movies/${id}`);
  }

  async getSearchResults(query) {
    return this.get(`search?search_text=${encodeURIComponent(query)}`);
  }

  async getChanelsWithScheduls() {
    return this.get('active-channels-with-schedules');
  }

  async getConfig() {
    return this.get('config');
  }

  async getPages() {
    return this.get('pages');
  }

  // Authentication Related APIs
  async Login(params) {
    return this.post('login', params);
  }

  async signUp(params) {
    return this.post('signup', params);
  }

  async forgotPassword(params) {
    return this.post('forgot-password', params);
  }

  // User Preferences APIs
  async postAddTofavourite(params) {
    return this.post('add-favourite', params);
  }

  async postRemovefavourite(params) {
    return this.post('remove-favourite', params);
  }

  async getWatchList() {
    return this.get('user-favourites');
  }

  // Watching History APIs
  async postContinueWatching(params) {
    return this.post('continue-watching', params);
  }

  async getContinueWatchingList() {
    return this.get('continue-watching');
  }

  // Subscription & Payment APIs
  async subscriptionPlans() {
    return this.post('subscription-plan', { platform: 'web' });
  }

  async createOrderID(amount) {
    const authString = `${RAZORPAY_CONFIG.KEY_ID}:${RAZORPAY_CONFIG.KEY_SECRET}`;
    const encodedAuth = btoa(authString);

    return HttpMethod._post(
      'https://api.razorpay.com/v1/orders',
      {
        amount,
        currency: "INR",
        receipt: `receipt_${Date.now()}`,
        notes: {
          platform: 'web',
          timestamp: new Date().toISOString()
        }
      },
      {
        'Authorization': `Basic ${encodedAuth}`
      }
    );
  }

  async purchase(params) {
    return this.post('purchase', params);
  }

  async purchaseMedia(params) {
    return this.post('purchase-media', params);
  }

  async verifyPayment(params) {
    return this.post('verify-purchase', params);
  }


  // tv-activation-api // 

  async tvActivation(body, headers) {
    return await HttpMethod._post(
      `${BASE_BACKEND_URL}tv/link-activation-code`,
      body,
      headers
    );
  }
  
}

// Create a singleton instance
const httpApis = new HttpApis();
Object.freeze(httpApis);

export default httpApis;