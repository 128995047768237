import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // Import Notyf styles
import "./AccountLink.css";
import HttpApis from "../../services/HttpApis";

const AccountLink = () => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const authtoken = useSelector((state) => state?.authReducer?.user?.data?.token);
  const navigate = useNavigate();

  const notyf = new Notyf({
    duration: 3000, // Notification duration in milliseconds
    position: { x: "center", y: "center" }, // Notification position
    types: [
      {
        type: "success",
        background: "green",
        icon: {
          className: "material-icons",
          tagName: "i",
          text: "check_circle",
        },
      },
      {
        type: "error",
        background: "red",
        icon: {
          className: "material-icons",
          tagName: "i",
          text: "error",
        },
      },
    ],
  });

  useEffect(() => {
    if (!authtoken) {
      navigate("/login");
    }
  }, [authtoken, navigate]);

  const handleChange = (value, index) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    setError(null);

    // Automatically focus the next input
    if (value && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handleActivate = async () => {
    const otpCode = code.join("");
    if (otpCode.length === 6) {
      setLoading(true);
      try {
        const response = await HttpApis.tvActivation(
          { activationCode: otpCode },
          { Authorization: `Bearer ${authtoken}` }
        );

        if (response?.status !== 200) {
          throw new Error(response?.message || "Activation failed");
        }

        const result = response?.data;
        console.log(result);

        // Show success notification
        notyf.success("Activation successful!");

        // Navigate to the desired page
        navigate('/');
      } catch (error) {
        // Show error notification
        notyf.error(error.message || "An unexpected error occurred");
      } finally {
        setLoading(false);
      }
    } else {
      // Show error notification for invalid OTP
      notyf.error("Please enter a valid 6-digit code");
    }
  };

  return (
    <div className="activation-screen">
      <h1 className="headingOTP">Code Verification</h1>
      <p className="paraOTP">Enter the verification code appears on your device.</p>
      <div className="otp-container">
        {code.map((digit, index) => (
          <input
            key={index}
            id={`otp-${index}`}
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(e.target.value, index)}
            className="otp-input"
          />
        ))}
      </div>
      <button className="activate-button" onClick={handleActivate} disabled={loading}>
        {loading ? "Activating..." : <span>&#10148;</span>}
      </button>
    </div>
  );
};

export default AccountLink;
