import React, { useEffect, useState } from "react";
import "./Hero.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiCircleCheck } from "react-icons/ci";
import { RiMovie2Line } from "react-icons/ri";
import { IoMdShare } from "react-icons/io";
import useDetailsHook from "../../hooks/DetailsHook";
import HttpApis from "../../services/HttpApis";
import { Modal } from "antd";
import { IoCopy } from "react-icons/io5";
import { BASE_BACKEND_URL } from "../../api/config";
import { btnColor } from "../../theme/color";
import { LeftOutlined, RightOutlined } from "@ant-design/icons"; // Import Ant Design arrow icons

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

const Hero = (props) => {
  const {
    title,
    description,
    info,
    posterUrl,
    data,
    type,
    handleDetails,
    details,
    handlePlayRoute,
    slug,
    addFavData,
  } = props;

  const loginUser = useSelector(
    (state) => state?.authReducer?.user?.data?.user
  );

  const navigate = useNavigate();
  const urlToShare = `${BASE_BACKEND_URL}`;
  const { formatTime } = useDetailsHook();
  const params = useParams();
  const authtoken = useSelector(
    (state) => state?.authReducer?.user?.data?.token
  );
  const [watchList, setWatchList] = useState();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const showLoading = () => {
    setOpen(true);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleRemove = async () => {
    const headers = {
      Authorization: `Bearer ${authtoken}`,
      "Content-Type": "application/json",
    };
    const params = { slug: slug };
    if (addFavData?.movies?.map((item) => item.slug === slug)) {
      const response = await HttpApis.postRemovefavourite(params, headers);
      if (response.status === 200) {
        const headers = {
          Authorization: `Bearer ${authtoken}`,
          "Content-Type": "application/json",
        };
        const response = await HttpApis.getWatchList("", headers);
        setWatchList(response.data.data);
        return response.data.data;
      }
      return response;
    }
    if (addFavData?.shows?.map((item) => item.slug === slug)) {
      const response = await HttpApis.postRemovefavourite(params, headers);
      if (response.status === 200) {
        const headers = {
          Authorization: `Bearer ${authtoken}`,
          "Content-Type": "application/json",
        };
        const response = await HttpApis.getWatchList("", headers);
        setWatchList(response.data.data);
        return response.data.data;
      }
      return response;
    } else {
      alert("there is no data to remove");
    }
  };
  const handleAdd = async () => {
    const headers = {
      Authorization: `Bearer ${authtoken}`,
      "Content-Type": "application/json",
    };
    const params = { slug: slug };
    const response = await HttpApis.postAddTofavourite(params, headers);
    if (response.status === 200) {
      const headers = {
        Authorization: `Bearer ${authtoken}`,
        "Content-Type": "application/json",
      };
      const response = await HttpApis.getWatchList("", headers);
      setWatchList(response.data.data);
      return response.data.data;
    }
    return response;
  };

  const handleCopy = () => {
    const link = `${BASE_BACKEND_URL}`;

    const tempInput = document.createElement("input");
    tempInput.value = link;
    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };

  return (
    <div className="hero-banner">
      <div className="hero-content">
        <h1 className="hero-title">{title}</h1>
        {info && info > 0 && <p className="hero-info">{formatTime(info)}</p>}
        <p className="hero-description">{description}</p>

        {details && loginUser ? (
          <div className="playButtonBox">
            <button
              className="hero-button"
              style={{ backgroundColor: btnColor }}
              onClick={() =>
                handlePlayRoute(
                  params.id,
                  params.type,
                  params.monitization_type
                )
              }
            >
              Play
            </button>
            <div className="trailerBox">
              <RiMovie2Line
                className="addIcon"
                onClick={() => handlePlayRoute(params.id, params.type)}
              />

              {watchList?.movies?.some((item) => item.slug === slug) ||
              watchList?.shows?.some((item) => item.slug === slug) ? (
                <div onClick={handleRemove}>
                  <CiCircleCheck className="subtractIcon" />
                </div>
              ) : (
                <div onClick={handleAdd}>
                  <IoIosAddCircleOutline className="addIcon" />
                </div>
              )}

              <div>
                <IoMdShare className="subtractIcon" onClick={showLoading} />
              </div>
            </div>
          </div>
        ) : loginUser ? (
          <button
            className="hero-button"
            style={{ backgroundColor: btnColor }}
            onClick={() => handleDetails(data, type)}
          >
            Details
          </button>
        ) : (
          <button
            className="hero-button"
            style={{ backgroundColor: btnColor }}
            onClick={() => handleLogin(data)}
          >
            Login
          </button>
        )}
      </div>

      <div
        className="hero-background"
        style={{
          backgroundImage: `
      linear-gradient(to right, rgb(0, 0, 0,1) 15%, rgba(0, 0, 0, 0.9) 35%, rgba(0, 0, 0, 0) 100%),
      url(${posterUrl})
    `,
        }}
      ></div>

      {/* Ant Design Custom Arrows */}

      <Modal
        footer={null}
        loading={null}
        open={open}
        onCancel={() => setOpen(false)}
        closeIcon={null}
      >
        <FacebookShareButton url={urlToShare}>
          <FacebookIcon className="socialIcon" round />
        </FacebookShareButton>

        <LinkedinShareButton url={urlToShare}>
          <LinkedinIcon className="socialIcon" round />
        </LinkedinShareButton>

        <WhatsappShareButton url={urlToShare}>
          <WhatsappIcon className="socialIcon" round />
        </WhatsappShareButton>

        <a href="javascript:void(0);" onClick={handleCopy}>
          <IoCopy className="socialIcon" />
        </a>
      </Modal>
    </div>
  );
};

export default Hero;
