import React, { useEffect, useState } from "react";
import DetailHero from "../Details/detailHero";
import Standard from "../Standard/standard";
import { useParams } from "react-router-dom";
import HttpApis from "../../services/HttpApis";
import "./style.css";
import useDetailsHook from "../../hooks/DetailsHook";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { btnColor } from "../../theme/color";
import { FaPlay } from "react-icons/fa";
import { FaRegUser, FaDollarSign } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { formatDate, formatTime } from "../../utils/formatingFunctions";

const Details = ({ handleDetails }) => {
  const [details, setDetails] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const params = useParams();
  const [addFavData, setAddFavData] = useState(false);
  const { handlePlayRoute } = useDetailsHook();
  const navigate = useNavigate();
  const authtoken = useSelector(
    (state) => state?.authReducer?.user?.data?.token
  );
  const isPremium = useSelector(
    (state) => state?.authReducer?.user?.data?.user.is_premium
  );
  const [status, setStatus] = useState("play");
  const [position, setPosition] = useState("0");
  const [continueWatching, setContinueWatching] = useState([]);

  const handleseason = (index) => {
    setActiveIndex(index);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (authtoken) {
      const getWatchData = async () => {
        const headers = {
          Authorization: `Bearer ${authtoken}`,
          "Content-Type": "application/json",
        };
        const response = await HttpApis.getWatchList("", headers);
        setAddFavData(response.data.data);
        return response.data.data;
      };
      getWatchData();
    }
  }, [authtoken]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        let response;
        if (params.type === "series") {
          response = await HttpApis.getSeriesList(params.id);
          setDetails(response?.data?.data);
          setEpisodes(response?.data?.data?.series?.seasons || []);
        } else {
          response = await HttpApis.getMoviesList(params.id);
          setDetails(response?.data?.data);
          // console.log("setDetails",response.data.data.movie.ty);
        }
        setDetails(response.data);
      } catch (error) {
        console.error(error);
        setDetails(null);
      }
    };
    fetchDetails();
  }, [params.id]);

  useEffect(() => {
    const getContinueWatching = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${authtoken}`,
          "Content-Type": "application/json",
        };
        const response = await HttpApis.getContinueWatchingList(headers);

        if (response?.data?.data?.length > 0) {
          // Filter data to find if the current id matches
          const filteredData = response.data.data.filter(
            (item) => item.media.id === Number(params.id)
          );

          //console.log("Filtered Data:", filteredData); // Check the filtered data

          setContinueWatching(filteredData); // Set filtered data to state
          setPosition(filteredData[0]?.progress_seconds);
          //console.log("filteredData.progress_seconds",filteredData, filteredData[0].progress_seconds);

          // Check if any matching data exists and update status
          if (filteredData.length > 0) {
            setStatus("resume");
          } else {
            setStatus("play");
          }
        }
      } catch (error) {
        setPosition(null);
        console.error("Error fetching continue watching data:", error);
        setContinueWatching([]); // Reset state if error occurs
        setStatus("play"); // Default status if an error occurs
      }
    };

    getContinueWatching();
  }, [authtoken, params.id]); // Dependency array to re-fetch if authtoken or params.id changes

  const fetchContinueStatus = () => {
    console.log("this is the status of the movies ::::: ", params.id);
  };

  useEffect(() => {
    fetchContinueStatus();
  }, [params.id]);

  if (!details) {
    return <Spin size="large" className="spiner" />;
  }

  console.log(episodes[activeIndex]?.episodes);
  return (
    <div>
      {params.type === "series" ? (
        <>
          <DetailHero
            banner={false}
            posterUrl={details?.data?.series?.thumbnail_image}
            title={details?.data?.series?.title}
            info="14+ | 2024 | Drama"
            description={details?.data?.series?.description}
            details={details.data.series}
            status={status}
            handlePlayRoute={handlePlayRoute}
            slug={details?.data?.series?.slug}
            addFavData={addFavData}
            position={position}
          />
          <div className="section">
            <div className="text">
              <h6>Episodes</h6>
              <hr style={{ opacity: 0.2 }} />
            </div>
            <div className="buttonBox">
              {episodes.map((season, index) => (
                <div key={index} className="seasonsBtn">
                  <button
                    className={activeIndex === index ? "active" : "nonactive"}
                    style={
                      activeIndex === index
                        ? { "--active-bg-color": btnColor }
                        : {}
                    }
                    onClick={() => handleseason(index)}
                  >
                    Season {index + 1}
                  </button>
                </div>
              ))}
            </div>
            <div className="episodeContainer">
              {episodes[activeIndex]?.episodes?.map((episode, index) => {
                const additionalInfoArray = [
                  episode?.imdb_rating ? `IMDB: ${episode.imdb_rating}` : null,
                  episode?.duration
                    ? `Duration: ${formatTime(episode.duration)}`
                    : null,
                  episode?.release_date
                    ? `Air date: ${formatDate(
                        episode.release_date,
                        "shortDate"
                      )}`
                    : null,
                  episode?.rating && episode?.rating.length > 0
                    ? `Rating: ${episode.rating.join(", ")}`
                    : null,
                  // episode.languages && episode.languages?.length > 0
                  //   ? `Languages: ${episode.languages.join(", ")}`
                  //   : null,
                ].filter((info) => info);
                return (
                  <div
                    key={index}
                    style={{
                      borderBottom: "0.5px solid rgb(51, 51, 51)",
                      paddingBottom: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div className="episodeCard">
                      <div style={{ position: "relative" }}>
                        <img
                          src={episode?.thumbnail_image}
                          alt="thumbnail_image"
                          className="episodesImage"
                          onClick={() =>
                            authtoken
                              ? handlePlayRoute(
                                  episode?.id,
                                  episode.type,
                                  episode.monetization_type
                                )
                              : handleLogin()
                          }
                        />
                        {/* <div className="playIcon"> */}
                        <div
                          className="iconBox"
                          onClick={() => {
                            if (
                              episode.monetization_type === "F" ||
                              isPremium
                            ) {
                              authtoken
                                ? handlePlayRoute(
                                    episode?.id,
                                    episode.type,
                                    episode.monetization_type
                                  )
                                : handleLogin();
                            } else {
                              navigate("/subscribe");
                            }
                          }}
                        >
                          {episode.monetization_type === "F" || isPremium ? (
                            <FaPlay />
                          ) : (
                            <FaDollarSign />
                          )}
                        </div>
                      </div>
                      {/* </div> */}

                      <div className="description">
                        <div className="playIcon">
                          <h5 className="heading">
                            {index + 1}.{episode?.title}
                          </h5>
                        </div>
                        {additionalInfoArray.length > 0 && (
                          <p
                            style={{ fontSize: "1.5vh" }}
                            className="Detailhero-info"
                          >
                            {additionalInfoArray.map((item, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && (
                                  <span className="separator"> | </span>
                                )}
                                {item}
                              </React.Fragment>
                            ))}
                          </p>
                        )}
                        {/* <div>{formatTime(episode?.duration)}</div> */}
                        <h6 className="descriptionText">
                          {episode?.description}
                        </h6>
                      </div>
                    </div>
                    {/* <hr style={{ border: "0.2px solid grey", opacity: 0.1 }} /> */}
                  </div>
                );
              })}
            </div>
            <div className="detailStandard">
              <Standard
                detailspage={true}
                detailList={details?.data?.related_series}
                handleDetails={handleDetails}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <DetailHero
            banner={false}
            posterUrl={details?.data?.movie?.thumbnail_image}
            title={details?.data?.movie?.title}
            description={details?.data?.movie?.description}
            details={details.data.movie}
            handlePlayRoute={handlePlayRoute}
            slug={details?.data?.movie?.slug}
            addFavData={addFavData}
            status={status}
            position={position}
          />
          <div className="detailStandard">
            <Standard
              detailspage={true}
              detailList={details?.data?.related_movies}
              handleDetails={handleDetails}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Details;
