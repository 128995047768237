import React from "react";
import { CiHeart } from "react-icons/ci";
import { MdFavorite } from "react-icons/md";

const FavoriteButton = ({ isInWatchList, handleAdd, handleRemove }) => {
  return (
    <div
      className={`favoriteButton ${isInWatchList ? "active" : ""}`}
      onClick={isInWatchList ? handleRemove : handleAdd}
      aria-label={isInWatchList ? "Remove from favorites" : "Add to favorites"}
    >
      {isInWatchList ? <MdFavorite /> : <CiHeart />}
    </div>
  );
};

export default FavoriteButton;
