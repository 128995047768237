import { useState, useEffect } from 'react';

export const useResponsive = () => {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const checkMobileTablet = () => {
      setIsMobileOrTablet(window.innerWidth <= 768);
    };
    
    checkMobileTablet();
    window.addEventListener("resize", checkMobileTablet);
    return () => window.removeEventListener("resize", checkMobileTablet);
  }, []);

  return { isMobileOrTablet };
};