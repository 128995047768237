import React, { useEffect, useState, useRef } from "react";
import * as Styled from "./style";
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import { formatDate, formatTime } from "../../utils/formatingFunctions";

const Standard = (props) => {
  const { sectionData, detailspage, detailList, handleDetails } = props;
  const [galleryRows, setGalleryRows] = useState([]);
  const [scrollPositions, setScrollPositions] = useState({});
  const rowRefs = useRef([]); // Array to store refs for each row

  const updateScrollPosition = (index) => {
    if (rowRefs.current[index]) {
      const { scrollLeft, scrollWidth, clientWidth } = rowRefs.current[index];
      setScrollPositions((prev) => ({
        ...prev,
        [index]: {
          scrollLeft,
          canScrollRight: scrollLeft + clientWidth < scrollWidth,
          canScrollLeft: scrollLeft > 0,
        },
      }));
    }
  };

  const moveRight = (index) => {
    if (rowRefs.current[index]) {
      const scrollAmount = window.innerWidth * 1; // 50% of the viewport width (50vw)
      rowRefs.current[index].scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
      setTimeout(() => updateScrollPosition(index), 300); // Update scroll position after animation
    }
  };

  const moveLeft = (index) => {
    if (rowRefs.current[index]) {
      const scrollAmount = window.innerWidth * 1; // 50% of the viewport width (50vw)
      rowRefs.current[index].scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
      setTimeout(() => updateScrollPosition(index), 300); // Update scroll position after animation
    }
  };

  useEffect(() => {
    if (Array.isArray(sectionData)) {
      const galleryItems = sectionData.filter(
        (item) => item?.type?.toLowerCase() === "gallery"
      );
      setGalleryRows(galleryItems);
    }
  }, [sectionData]);

  useEffect(() => {
    galleryRows.forEach((_, index) => {
      updateScrollPosition(index);
    });
  }, [galleryRows]);

  return (
    <div>
      {detailspage ? (
        <>
          {detailList && (
            <Styled.StandardBox>
              <Styled.HeadingBox className="heading">
                More like this
              </Styled.HeadingBox>
              <Styled.MainContainer>
                {detailList?.map((item, index) => {
                  // console.log(item);
                  return (
                    <div key={index} className="cardbox">
                      <div
                        onClick={() => handleDetails(item.id, item.type)}
                        className="overlay"
                      >
                        <span>
                          {item?.description && item.description.length > 30
                            ? item.description.substring(0, 200) + "..."
                            : item?.description || null}
                        </span>
                        <br />
                        <div className="details-container">
                          {item?.imdb_rating && (
                            <span>IMDB {item?.imdb_rating}</span>
                          )}
                          {item?.release_date && (
                            <span>
                              {formatDate(item?.release_date, "year")}
                            </span>
                          )}
                          {item?.duration && (
                            <span>{formatTime(item?.duration)}</span>
                          )}
                        </div>
                      </div>
                      <div
                        onClick={() => handleDetails(item.id, item.type)}
                        className="imageContainer"
                      >
                        <img
                          src={item.thumbnail_image}
                          alt=""
                          className="imagebox"
                        />
                      </div>
                      {/* <div className="para">{item.title}</div> */}
                    </div>
                  );
                })}
              </Styled.MainContainer>
            </Styled.StandardBox>
          )}
        </>
      ) : (
        <>
          {galleryRows.length > 0
            ? galleryRows.map((row, rowIndex) => (
                <Styled.StandardBox key={rowIndex}>
                  <Styled.HeadingBox className="heading">
                    {row.title}
                  </Styled.HeadingBox>
                  <Styled.MainContainer>
                    {scrollPositions[rowIndex]?.canScrollLeft && (
                      <Styled.ButtonLeft
                        className="buttonLeft"
                        onClick={() => moveLeft(rowIndex)} // Pass rowIndex to moveLeft
                      >
                        <FaChevronLeft />
                      </Styled.ButtonLeft>
                    )}

                    {scrollPositions[rowIndex]?.canScrollRight && (
                      <Styled.ButtonRight
                        className="buttonRight"
                        onClick={() => moveRight(rowIndex)} // Pass rowIndex to moveRight
                      >
                        <FaChevronRight />
                      </Styled.ButtonRight>
                    )}

                    <div
                      ref={(el) => (rowRefs.current[rowIndex] = el)} // Assign ref dynamically
                      onScroll={() => updateScrollPosition(rowIndex)} // Update scroll position on scroll
                      style={{
                        display: "flex",
                        transition: "transform 0.3s ease",
                        overflowX: "auto", // Enables horizontal scrolling
                        scrollbarWidth: "none", // Firefox
                        msOverflowStyle: "none", // Internet Explorer 10+
                        gap: "30px", // Add gap between images
                        marginRight: "16px", // Margin to the right of the row
                      }}
                    >
                      {row.items.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="cardbox"
                            style={{ flex: "0 0 auto" }}
                          >
                            <div
                              onClick={() => {
                                // console.log(item);
                                handleDetails(item.id, item.type);
                              }}
                              className="overlay"
                            >
                              <span>
                                {item?.description &&
                                item.description.length > 30
                                  ? item.description.substring(0, 200) + "..."
                                  : item?.description || null}
                              </span>
                              <br />
                              <div className="details-container">
                                {item?.imdb_rating && (
                                  <span>IMDB {item?.imdb_rating}</span>
                                )}
                                {item?.release_date && (
                                  <span>
                                    {formatDate(item?.release_date, "year")}
                                  </span>
                                )}
                                {item?.duration && (
                                  <span>{formatTime(item?.duration)}</span>
                                )}
                              </div>
                            </div>
                            <div
                              onClick={() => handleDetails(item.id, item.type)}
                              className="imageContainer"
                            >
                              <img
                                src={item.thumbnail_image}
                                alt=""
                                className="imagebox"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Styled.MainContainer>
                </Styled.StandardBox>
              ))
            : null}
        </>
      )}
    </div>
  );
};

export default Standard;
