import styled from "styled-components";
import { btnColor } from "../../theme/color";

export const NavContainer = styled.div`
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 99999999999; /* Ensure the navbar stays on top of other content */
  background: rgba(25, 25, 25, 0.9);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff;
  align-items: center;
  padding: 10px 20px;
  height: 10vh;
  transition: transform 0.3s, box-shadow 0.3s; /* Include box-shadow transition */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  .logoImage {
    height: 8vh;
    @media (max-width: 700px) {
      height: 30px;
    }
  }

  .menu_item {
    position: absolute;
    z-index: 11;
  }
  .menuAndLogo {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    align-items: center;
  }
`;

export const MenueContainer = styled.div`
  flex: 0.5;
  .menubox {
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
    align-items: center;
    gap: 50px;
    margin-left: 50px;
    padding-top: 5px;
  }
  .listdata {
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    padding-bottom: 4px;
    border-bottom: 4px solid transparent;
  }

  .activeListdata {
    border-bottom: 4px solid ${btnColor};
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    padding-bottom: 4px;
    border-radius: 2px;
  }
  .listdata:hover {
    border-bottom: 4px solid ${btnColor};
    border-radius: 2px;
  }
  .premiumButton {
    background: red;
    border: none;
    color: white;
    height: 45px;
    font-size: 22px;
    font-weight: bold;
    border-radius: 22px;
    width: 120px;
  }

  // this  is dot in front  of the live icon
  @keyframes wave {
    0% {
      opacity: 0.5;
      transform: scale(0);
    }
    50% {
      opacity: 0.6;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(2);
    }
  }

  .dot {
    display: inline-block;
    width: 7px; /* Updated size */
    height: 7px; /* Updated size */
    background-color: white; /* White color */
    border-radius: 50%;
    margin-left: 5px;
    position: relative;
  }

  .dot::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 7px; /* Updated size */
    height: 7px; /* Updated size */
    background-color: white; /* White color */
    border-radius: 50%;
    animation: wave 1.5s infinite;
    animation-delay: 0.5s;
    opacity: 0;
  }

  .dot::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 7px; /* Updated size */
    height: 7px; /* Updated size */
    background-color: white; /* White color */
    border-radius: 50%;
    animation: wave 1.5s infinite;
    animation-delay: 1s;
    opacity: 0;
  }
  .liveTextContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 5px 0px 5px;
  }

  // .active {
  //   border-bottom: 4px solid ${btnColor};;
  //   font-size: 3vh;
  //   font-family: 'MyCustomFont';
  //   font-weight: bold;
  // }
`;

export const RightContainer = styled.div`
  @media (max-width: 900px) {
    gap: 8px;
    padding: 0px;
    align-items: center;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 10px;

  .searchbox {
    padding: 10px 15px;
    outline: none; /* Remove the default outline */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 5px; /* Rounded corners */
    font-size: 2vh; /* Font size */
    width: 100px; /* Default width for desktop */
    transition: border-color 0.3s; /* Smooth transition for border color */

    @media (max-width: 900px) {
      width: 20px; /* Adjust width for tablets */
    }

    @media (max-width: 600px) {
      width: 200px; /* Adjust width for mobile */
    }
  }

  .searchbox:focus {
    border-color: ${btnColor};; /* Green border on focus */
    box-shadow: 0 0 5px rgba(43, 128, 0, 0.5); /* Slight shadow effect on focus */
  }

   .userIcon {
    font-size: 26px;
    margin: 0px;
    padding: 0px;
     
     
  }
  .btn {
    // height: 51px;
    // text-align: center;
    background: #2b8000;
    border-radius:22px;
    font-weight:600;
    letter-spacing:1px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    border: none;
    // width: 189px;
    // font-size: 2vh;
    // font: 700 15px / 29px Open Sans, sans-serif;
    @media (max-width: 900px) {
      display:none;
    }
  }


    .profileDropdown {
  position: absolute;
  top: 100%;
  margin-left: -200px;
  background: black;
  align-items: center;
  border-radius: 20px;
  z-index: 1000;
  width: 300px;
  padding: 5px;
}

/* Styles for mobile devices */
@media (max-width: 600px) {
  .profileDropdown {
    width: 60%;
    margin-left: -40%;
    
    padding: 5px;
  }

  .profileItem {
    padding: 10px;
    margin: 5px;
    font-size: 18px;
  }
}

/* Styles for tablets and larger mobile devices */
@media (min-width: 601px) and (max-width: 1024px) {
  .profileDropdown {
    width: 80%;
    margin-left: -40%;
    top: 50px;
    padding: 5px;
  }

  .profileItem {
    padding: 15px;
    margin: 5px;
    font-size: 18px;
  }
   
}
}
.profileIcon{
   display:flex ;
   font-size:26px;
    
   @media (min-width: 300px) and (max-width: 500px){
    font-size:20px;
   }
    @media (min-width: 500px) and (max-width: 1024px){
    font-size:22px;
   }
}
.profileItem {
  position: relative; /* Required for the pseudo-element */
  font-family: 'MyCustomFont';
  font-weight: bold;
  font-size: 20px;
  color: white;
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 5px;
  gap:10px;
  cursor: pointer;
  overflow: hidden; /* To ensure the pseudo-element doesn't overflow */
}

.profileItem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: ${btnColor};;
  
  z-index: -1; /* Ensure the background color is behind the text */
}

.profileItem:hover::before {
  width: 100%;
}

  .settingIcon {
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    font-family: 'MyCustomFont';
    font-weight: bold;
    border-bottom:4px solid transparent;
    height: 40px;
    width: 31px;
    @media (max-width: 900px) {
      font-size: 20px;
    }
  }

  .settingIcon:hover{
    border-bottom: 4px solid ${btnColor};
    border-radius: 2px;
  }

  .searchIcon {
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    cursor:pointer;
    justify-content: center;
    border-bottom: 4px solid transparent ;
    
    // height: 51px;
    // width: 41px;
    @media (max-width: 900px) {
      font-size: 20px;
    }
  }

  .searchIcon:hover{
    border-bottom: 4px solid ${btnColor};
    border-radius: 2px;
  }

  .hidesearch {
    opacity: 0;
  }
  .searchContainer {
    display: flex;
    border: none;
  }
`;

export const ImageContainer = styled.div`
  .navIcon {
    width: 24px;
  }
`;

export const MenuBox = styled.div`
  .menubox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    font-family: "MyCustomFont";
    font-weight: bold;
  }
  .dropIcon {
    font-size: 24px;
  }
`;

export const MenuList = styled.div`
  position: absolute;
  top: 56px;
  z-index: 11;
  background: #222020;

  .menuListContainer {
    list-style: none;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 20px;
    width: 150px;
    padding-left: 40px;
    font-family: "MyCustomFont";
    font-style: bold;
  }
`;
