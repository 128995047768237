export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600); // Calculate hours
  const minutes = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes

  // Conditionally format based on hours
  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${minutes}m`; // Only show minutes if hours are 0
  }
};

export const formatDate = (timestamp, format) => {
  // Convert the timestamp to milliseconds (JavaScript's Date constructor expects milliseconds)
  const date = new Date(timestamp * 1000);

  // Helper function to pad single digit months, days, etc.
  const pad = (num) => num.toString().padStart(2, "0");

  // Return the formatted date based on the requested format
  switch (format) {
    case "year":
      return date.getFullYear(); // Return year (e.g., 2025)
    case "shortDate":
      return `${pad(date.getMonth() + 1)}/${pad(
        date.getDate()
      )}/${date.getFullYear()}`; // Return MM/DD/YYYY (e.g., 01/22/2025)
    case "longDate":
      return date.toLocaleDateString(); // Return the full date string (e.g., January 22, 2025)
    case "time":
      return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
        date.getSeconds()
      )}`; // Return time (e.g., 10:30:00)
    default:
      return date.toLocaleString(); // Default: Return full date and time string
  }
};
