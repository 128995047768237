// hooks/usePaymentService.js
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PaymentFactory } from '../services/payment.factory';
import { PAYMENT_CONSTANTS } from '../constants/payment.constants';
import HttpApis from '../services/HttpApis';
import { Notyf } from "notyf";
import 'notyf/notyf.min.css';

const usePaymentService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentGateway, setPaymentGateway] = useState(null);

  const authtoken = useSelector((state) => state?.authReducer?.user?.data?.token);
  const user = useSelector((state) => state?.authReducer?.user?.data?.user);
  const activePaymentMethod = localStorage.getItem(PAYMENT_CONSTANTS.STORAGE_KEYS.PAYMENT_METHOD);

  const notyf = new Notyf({
    position: {
      x: "center",
      y: "center",
    },
  });

  useEffect(() => {
    if (activePaymentMethod) {
      initializePaymentGateway();
    }
  }, [activePaymentMethod]);

  const initializePaymentGateway = async () => {
    try {
      const gateway = PaymentFactory.getPaymentGateway(activePaymentMethod);
      const isLoaded = await gateway.loadScript();

      if (!isLoaded) {
        throw new Error(`Failed to load ${activePaymentMethod} script`);
      }

      setPaymentGateway(gateway);
      setError(null);
    } catch (err) {
      setError(`Payment initialization failed: ${err.message}`);
      setPaymentGateway(null);
    }
  };

  const createOrder = async (plan) => {
    try {
      const orderResponse = await HttpApis.purchase({
        purchase_type: plan?.payment_type || 'subscription',
        product_id: plan.productId,
        media_type: plan?.media_type || '',
      });

      if (!orderResponse.status) {
        throw new Error('Failed to create payment order');
      }

      return orderResponse.data.data.orderId;
    } catch (error) {
      console.error('Error creating order:', error);
      throw error;
    }
  };

  const handlePayPalButton = async (plan) => {
    if (!paymentGateway || !plan.plan_id) return;

    try {
      await paymentGateway.renderButtons(`paypal-button-${plan.plan_id}`, {
        createOrder: async () => createOrder(plan),
        onApprove: async (data) => {
          try {
            setLoading(true);
            const result = await paymentGateway.verifyPayment({
              paymentID: data.paymentID,
              orderID: data.orderID,
              payment_type: 'subscription'
            });

            if (result.success) {

              notyf.success(result.message);

            } else {
              throw new Error(result.message);
            }
          } catch (err) {
            setError(err.message || 'Payment verification failed');
          } finally {
            setLoading(false);
          }
        },
        onError: (err) => {
          console.error('PayPal error:', err);
          setError('Payment failed. Please try again.');
        },
        onCancel: () => {
          setError('Payment was cancelled');
        }
      });
    } catch (err) {
      console.error('Error setting up PayPal button:', err);
      setError('Failed to initialize payment button');
    }
  };

  const handlePayPalPayment = async (plan, orderData) => {
    console.log(plan);
    if (!paymentGateway) throw new Error('PayPal not initialized');

    const buttons = paymentGateway.createPayPalButtons({
      createOrder: () => orderData.orderId,
      onApprove: async (data) => {
        try {
          const result = await paymentGateway.verifyPayment({
            payment_id: data.paymentID,
            order_id: data.orderID,
            payment_type: 'subscription'
          });

          if (result.success) {
            alert('Subscription successful!');
            window.location.reload();
          } else {
            throw new Error(result.message);
          }
        } catch (err) {
          setError(err.message);
        }
      },
      onError: (err) => setError('Payment failed. Please try again.'),
      onCancel: () => setError('Payment was cancelled')
    });

    await buttons.render(`#paypal-button-${plan.plan_id}`);
  };

  const handleRazorpayPayment = async (plan, orderData) => {
    if (!paymentGateway) throw new Error('Razorpay not initialized');

    await paymentGateway.initializePayment({
      publicKey: orderData.public_key,
      amount: orderData.amount,
      currency: orderData.currency,
      orderId: orderData.orderId,
      description: `Subscription to ${plan.plan_name}`,
      userInfo: {
        name: user?.name,
        email: user?.email,
        contact: user?.contact
      },
      metadata: {
        plan_id: plan.plan_id,
        payment_type: 'subscription'
      },
      onSuccess: async (response) => {
        try {
          const result = await paymentGateway.verifyPayment({
            payment_id: response.razorpay_payment_id,
            order_id: response.razorpay_order_id,
            signature: response.razorpay_signature,
            payment_type: 'subscription'
          });

          if (result.success) {
            alert('Subscription successful!');
            window.location.reload();
          } else {
            throw new Error(result.message);
          }
        } catch (err) {
          setError(err.message);
        }
      },
      onError: (err) => setError('Payment failed. Please try again.')
    });
  };

  const handlePayment = async (plan) => {
    try {
      setLoading(true);
      const orderResponse = await HttpApis.purchase({
        purchase_type: plan?.payment_type || 'subscription',
        product_id: plan.productId,
        media_type: plan?.media_type || '',
      });

      if (!orderResponse.status) {
        throw new Error('Failed to create payment order');
      }

      if (activePaymentMethod === 'paypal') {
        await handlePayPalPayment(plan, orderResponse.data.data);
      } else {
        await handleRazorpayPayment(plan, orderResponse.data.data);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const initializePayPalButtons = async (plans) => {
    console.log(plans);
    if (activePaymentMethod === 'paypal' && paymentGateway && plans.length > 0) {
      for (const plan of plans) {
        try {
          console.log(plan);
          await handlePayPalButton(plan);
        } catch (err) {
          console.error(`Failed to initialize PayPal button for plan ${plan.plan_id}:`, err);
          setError(`Failed to initialize payment button for ${plan.plan_name}`);
        }
      }
    }
  };

  return {
    loading,
    error,
    paymentGateway,
    activePaymentMethod,
    handlePayment,
    initializePayPalButtons,
    setError
  };
};

export default usePaymentService;