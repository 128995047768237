import React from "react";
import { formatDate, formatTime } from "../../utils/formatingFunctions";

const HeroContent = ({ title, description, children, details }) => {
  const { categories, category, languages } = details;

  const allCategories = [
    ...(categories || []), // Use `categories` if available
    ...(category || []), // Use `category` if available
  ];

  // console.log(details);

  // Combine details into a single array
  const additionalInfoArray = [
    details?.imdb_rating ? `IMDB: ${details.imdb_rating}` : null,
    details?.duration ? `Duration: ${formatTime(details.duration)}` : null,
    details?.release_date
      ? `Air date: ${formatDate(details.release_date, "shortDate")}`
      : null,
    details?.rating && details?.rating.length > 0
      ? `Rating: ${details.rating.join(", ")}`
      : null,
    languages && languages.length > 0
      ? `Languages: ${languages.join(", ")}`
      : null,
  ].filter((info) => info); // Remove null/undefined items

  return (
    <div className="Detailhero-content">
      <h1 className="Detailhero-title">{title}</h1>
      {additionalInfoArray.length > 0 && (
        <p className="Detailhero-info">
          {additionalInfoArray.map((item, index) => (
            <React.Fragment key={index}>
              {index > 0 && <span className="separator"> | </span>}
              {item}
            </React.Fragment>
          ))}
        </p>
      )}
      <span className="Detailhero-description">{description}</span>
      {allCategories && allCategories?.length > 0 ? (
        <div className="category-container">
          {allCategories.map((cat, index) => (
            <p key={index} className="category">
              {cat}
            </p>
          ))}
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default HeroContent;
