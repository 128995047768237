import { RazorpayGateway } from './payment-gateways/razorpay.gateway';
import { PayPalGateway } from './payment-gateways/paypal.gateway';

export class PaymentFactory {
  static getPaymentGateway(gateway) {
    switch (gateway.toLowerCase()) {
      case 'razorpay':
        return new RazorpayGateway();
      case 'paypal':
        return new PayPalGateway();
      default:
        throw new Error(`Unsupported payment gateway: ${gateway}`);
    }
  }
}
