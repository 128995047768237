import styled from "styled-components";
export const VideoContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  

  .header{
    position: absolute;
    top: 0px;
    width:100%;
    height:10%;
    background:rgba(0, 0, 0, 0.6);
    // left: 20px;
    padding:15px;
    color: white;
    display:flex;
    justify-content:start;
    align-items:center;
    gap:10px;
  }

  .backbutton{
    width:40px;
    font-size: 25px;
    cursor: pointer;
    margin-left:20%
    display:flex;
    justify-content:center;
    align-items:center;
    // padding: 10px;
    // border-radius: 50px;
    // background:yellow;
    transition: background 0.3s;
    &:hover {
    cursor: pointer;
    }
  }


  button {
      background: none;
      color: red;
      border: none;
      cursor: pointer;
      font-size: 10px;
      transition: transform 0.2s;
      &:hover {
        transform: scale(1.1);
      }
    }
  .controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: none;
    padding: 15px;
    // border-radius: 15px;
    background:rgba(0, 0, 0, 0.6);
    // transition: opacity 2s ease; 
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    gap: 10px;
    z-index: 10;

  .timeBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // background:yellow;
      // margin-left:-3vw;
   
      div {
        color: white;
        font-size: 18px;
        font-weight:bold;
      }
  }
  .titleTextplayer{
    display:flex;
    font-size: 18px;
    font-weight:bold;
  }

  .timeline{
      width: 100%;
  }

  .seekWidth {
      width: 100%; 
      max-width: 100%; 
      margin: 0 auto;
      display: block; 
      accent-color:red
  }

  .timeline input{
  height:4px;
  }
  

  
 .volumeBox {
  align-items: center;
  display:flex;
  justify-content: center;
  // width:100px;
  // height: 100px;
  // margin: 0 10px;
}
 .sliderVolume {
  accent-color:white
  }

  .volumeBox input{
  height:5px;
  }

  .title-text {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.8);
    z-index: 10;
    display:flex;
  }
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  // background: yellow;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  .iconStyle {
    color: white;
    font-size: 25px;

    &:hover {
    }
  }

  .leftBox {
    display: flex;
    align-items: center;
    gap: 20px;
    // margin-left: -3vw;
  }

  .fullScreenText {
    display: flex;
    align-items: center;
    margin-left: 74vw;
  }
`;
