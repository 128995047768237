import React from 'react';
import { Modal } from 'antd';
import { IoCopy } from "react-icons/io5";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

const ShareModal = ({ open, setOpen, handleCopy, BASE_BACKEND_URL }) => {
  return (
    <Modal
      footer={null}
      loading={null}
      open={open}
      onCancel={() => setOpen(false)}
      closeIcon={null}
    >
      <FacebookShareButton url={BASE_BACKEND_URL}>
        <FacebookIcon className="socialIcon" round />
      </FacebookShareButton>
      <LinkedinShareButton url={BASE_BACKEND_URL}>
        <LinkedinIcon className="socialIcon" round />
      </LinkedinShareButton>
      <WhatsappShareButton url={BASE_BACKEND_URL}>
        <WhatsappIcon className="socialIcon" round />
      </WhatsappShareButton>
      <a href="javascript:void(0);" onClick={handleCopy}>
        <IoCopy className="socialIcon" />
      </a>
    </Modal>
  );
};

export default ShareModal;