import React from 'react';
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoMdShare } from "react-icons/io";
import { FaPlay } from "react-icons/fa6";

const MobileOptions = ({ showMoreOptions, handleAdd, showLoading, handleButtonClick }) => {
  return (
    showMoreOptions && (
      <div className="moreOptions">
        <div onClick={handleAdd}>
          <IoIosAddCircleOutline className="addIcon" />
          Add to Favorites
        </div>
        <div onClick={showLoading}>
          <IoMdShare className="subtractIcon" />
          Share
        </div>
        <div onClick={handleButtonClick}>
          <FaPlay className="playIcon" />
          Play Now
        </div>
      </div>
    )
  );
};

export default MobileOptions;