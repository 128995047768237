import React, { useState } from "react";

const Search = ({ backGround, placeholder, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value); // Call the onSearch function with the search term
  };

  const searchStyle = {
    backgroundColor: backGround || "#fff",
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "0px solid #000",
    color: "#fff",
    outline: "none",
  };

  return (
    <input
      type="text"
      autoFocus
      style={searchStyle}
      placeholder={placeholder}
      value={searchTerm}
      onChange={handleInputChange}
    />
  );
};

export default Search;
