export const PAYMENT_CONSTANTS = {
  STORAGE_KEYS: {
    PAYMENT_METHOD: 'payment_method'
  },
  GATEWAY_SCRIPTS: {
    paypal: 'https://www.paypal.com/sdk/js',
    razorpay: 'https://checkout.razorpay.com/v1/checkout.js'
  },
  MERCHANT_NAME: 'Your Company Name',
  THEME_COLOR: '#3399cc'
};
