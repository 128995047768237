import React from "react";
import { useNavigate } from "react-router-dom";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useDetailsHook = () => {
  const navigate = useNavigate();
  const authtoken = useSelector((state) => state?.authReducer?.user?.data?.token);
  const isPremium = useSelector((state) => state?.authReducer?.user?.data?.user.is_premium);
  const location = useLocation();

  const notyf = new Notyf({
    position: { x: "center", y: "center" },
  });

  const handlePlayRoute = (details) => { 
    const { id, type, monetization,access_info, position } = details || {};
    if (!authtoken || authtoken === "undefined" || authtoken === "null") {
      navigate("/login");
      return;
    }
  
    if (monetization?.monetization_type === "free" || access_info?.has_access) {
      const route = position
        ? `/player/${type}/${id}?position=${position}`
        : `/player/${type}/${id}`;
      navigate(route, { state: { startTime: position } });
    } else {
      notyf.error("This content requires a premium subscription.");
      navigate("/subscribe");
    }
  };
  
  
  

  const formatTime = (seconds = 0) => {
    if (isNaN(seconds) || seconds < 0) return "00:00:00";
    
    const hrs = Math.floor(seconds / 3600).toString().padStart(2, "0");
    const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
    const secs = Math.floor(seconds % 60).toString().padStart(2, "0");
  
    return `${hrs}:${mins} min`;
  };
  
  // Example usage:

  

  return { handlePlayRoute, formatTime };
};

export default useDetailsHook;
