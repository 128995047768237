import React from "react";
import "./Devices.css";
import { useNavigate } from "react-router-dom";

const Devices = () => {
  const devices = [
    { type: "ios", id: "16d221e6-3a3a-480c-a33e-c62ebdcca876", addedOn: "Nov 24, 2024" },
    { type: "roku", id: "633f40eb-6e28-5d52-9511-4e94827379af", addedOn: "Nov 04, 2024" },
    { type: "roku", id: "23fcf9b5-ff7d-5415-b5c3-16ea5e514dbf", addedOn: "Oct 26, 2024" },
    { type: "android", id: "1ea9175e-9cd0-46a3-98a5-547455a0530b", addedOn: "Oct 26, 2024" },
  ];
  const Navigate = useNavigate();

  const handleLink=()=>{
    Navigate('/link');
  }

  return (
    <div className="devices-container">
      <header className="devices-header">
        <h2>Devices</h2>
        <button className="link-device-button"  onClick={handleLink}>+ Link a New Device</button>
      </header>
      <p className="devices-note">
        You can link up to <strong>6 devices</strong> to your account at one time.
      </p>
      <div className="devices-list">
        {devices.map((device, index) => (
          <div key={index} className="device-item">
            <div className="device-details">
              <h3>{device.type}</h3>
              <p><span>ID:</span> {device.id}</p>
              <p><span>Added on:</span> {device.addedOn}</p>
            </div>
            <button className="unlink-button">Unlink</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Devices;
