import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 10px 0px 50px 10px;
  display: flex;
  gap: 50px;
  overflow-y: hidden;
  scrollbar-width: none;
  padding-bottom: 2px;
  flex-direction: row;
  position: relative;
  z-index: 9999;
  background-color: #191919;

  .cardbox {
    position: relative;
    margin-bottom: 10px;
    box-shadow: 3px 4px 3px rgba(0, 0, 0, 1);
    &:hover .overlay {
      opacity: 1;
      &:hover {
      }
    }
  }

  .overlay {
    position: absolute;
    height: 100%;
    color: grey;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 1)
    );
    opacity: 0;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: end;
    cursor: pointer;
    transition: opacity 0.8s ease;
    padding: 10px;
  }

  .overlay span {
    font-size: 0.8rem;
    // width: 100%;
  }

  .overlay div {
    display: flex;
    gap: 10px;
    font-weight: 600;
    color: grey;
  }

  .para {
    margin: 0px;
    color: #ffffff;
    padding-top: 10px;
    font-family: "MyCustomFont";
    font-weight: bold;
  }

  .imagebox {
    height: 30vh;
    width: 25vw;
    border-radius: 3px;
    font-family: "MyCustomFont";
    font-weight: normal;
    transition: transform 0.3s;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 1); /* Optional, for effect */
  }

  .imagebox:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    padding: 10px 0px 50px 10px;
    z-index: initial;
    .imagebox {
      width: 180px;
      height: 120px;
      border-radius: 10px;
    }

    .para {
      font-size: 12px;
      font-style: bold;
      width: 200px;
      height: 25px;
    }
  }

  &:hover .buttonLeft,
  &:hover .buttonRight {
    display: flex;
  }
`;

export const HeadingBox = styled.div`
  padding-left: 10px;
  padding-top: 10px;
  margin: 0px;
  color: #ffffff;
  font-size: 22px;
  font-family: "MyCustomFont";
  font-weight: bold;
  position: relative;

  @media (max-width: 768px) {
    font-size: 15px;
    margin-top: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
  }
`;

export const StandardBox = styled.div`
  padding-left: 15px;
  background-color: #191919;
  padding-top: 10px;
  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
    height: 180px;
  }
`;

export const ButtonLeft = styled.div`
  z-index: 99999;
  color: white;
  position: absolute;
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
  // transition: transform 0.3s ease, background-color 0.3s ease;
  // backdrop-filter: blur(5px);
  // background-color: rgba(0, 0, 0, 0.3);
  width: 60px;
  height: 100%;
  // border-radius: 50%;

  // margin-top: 5%;
  display: none; // Initially hidden
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.2);
    color: red !important;
  }
`;

export const ButtonRight = styled.div`
  z-index: 9999;
  position: absolute;
  font-size: 30px;
  padding: 10px;
  // border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  margin-left: 94%;
  // margin-top: 5%;
  color: white;
  // backdrop-filter: blur(5px);
  // background-color: rgba(0, 0, 0, 0.3);
  width: 60px;
  height: 100%;
  display: flex; // Initially hidden

  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.2);
    color: red !important;
  }
`;
