import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/MenuItem.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Styled from "./style";
import { MdOutlineSettings } from "react-icons/md";
import Search from "../Search/Search";
import { useSelector } from "react-redux";
import useWindowSize from "../../hooks/windowScreenHook";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";
import HttpApis from "../../services/HttpApis";
import {
  DownOutlined,
  SettingOutlined,
  UserOutlined,
  HeartOutlined,
  AlertOutlined,
  RightCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { logout } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

const MenuItems = (props) => {
  const { handleSectionClick, error } = props;
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Menu");
  const { width } = useWindowSize();
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const menuList = useSelector((state) => state?.menuReducer?.menuList?.data);
  const loginUser = useSelector(
    (state) => state?.authReducer?.user?.data?.user
  );
  const isPremium = useSelector(
    (state) => state?.authReducer?.user?.data?.user.is_premium
  );
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const userName = loginUser?.name.toUpperCase();
  const [appLogo, setAppLogo] = useState("");

  const searchContainerRef = useRef(null);
  const menuContainerRef = useRef(null);
  const profileDropdownRef = useRef(null);
  // console.log("isPremium", isPremium);
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "center",
    },
  });
  const onSearch = async (query) => {
    try {
      const response = await HttpApis.getSearchResults(query);
      const results = response.data.data;
      const combinedResults = [
        ...results.movies.map((movie) => ({
          ...movie,
          type: "movie", // Keep the type to identify it in Grid
        })),
        ...results.shows.map((show) => ({
          ...show,
          type: "series", // Keep the type to identify it in Grid
        })),
      ];

      navigate("/search", {
        state: { searchResults: combinedResults, searchText: query },
      });
    } catch (error) {
      setSearchResults([]); // Clear results or handle error as needed
    }
  };

  const handleSearch = () => {
    setShowSearch(true);
  };

  const handleHide = () => {
    setShowSearch(false);
  };

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSettings = () => {
    navigate("/settings");
  };

  const navigateToAccountPage = () => {
    navigate("/accountPage");
  };

  const navigateSubscribe = () => {
    navigate("/subscribe");
  };

  const handleSignOut = () => {
    dispatch(logout());
    notyf.success("You have successfully signed out.");
    setShowProfileDropdown(false);
    navigate("/");
  };

  const handleMenuItemClick = (item, index) => {
    handleSectionClick(item, "/");
    setActiveIndex(index);
    setSelectedMenuItem(item?.name);
    setShowMenu(false); // Close the menu after selecting an item
  };

  const homeNavigation = () => {
    navigate("/");
  };

  const handleClickOutside = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target)
    ) {
      setShowSearch(false);
    }
    if (
      menuContainerRef.current &&
      !menuContainerRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
    if (
      profileDropdownRef.current &&
      !profileDropdownRef.current.contains(event.target)
    ) {
      setShowProfileDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    const fetchConfig = async () => {
      try {
        const response = await HttpApis.getConfig();
        if (response.data.status && response.data.data.App_setting) {
          setAppLogo(response.data.data.App_setting[0].app_logo);
        }

        // Store payment_method in local storage
        if (response.data.data.payment_method) {
          localStorage.setItem(
            "payment_method",
            response.data.data.payment_method
          );
        }
        if (response.data.data.public_key) {
          localStorage.setItem("public_key", response.data.data.public_key);
        }
      } catch (err) {
      } finally {
      }
    };
    fetchConfig();
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const profileItems = [
    {
      key: "2",
      label: "Continue Watching",
      icon: <RightCircleOutlined />,
      onClick: navigateToAccountPage,
    },
    {
      key: "3",
      label: "Favourites",
      icon: <HeartOutlined />,
      onClick: navigateToAccountPage,
    },
    {
      key: "4",
      label: "Account",
      icon: <SettingOutlined />,
      onClick: navigateToAccountPage,
    },

    {
      key: "6",
      label: "Sign out",
      icon: <LogoutOutlined />,
      onClick: handleSignOut,
    },
  ];

  const handleProfileItemClick = (key, onClick) => {
    console.log(`Profile item ${key} clicked`);
    if (onClick) {
      onClick();
    }
  };

  const handleProfile = () => {
    console.log("Profile icon clicked");
    setShowProfileDropdown(!showProfileDropdown);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Styled.NavContainer>
        {width < 1000 ? (
          <>
            <div>
              <img
                src={appLogo}
                alt="logo"
                className="logoImage"
                onClick={homeNavigation}
              />
            </div>
            <Styled.MenuBox ref={menuContainerRef}>
              <div className="menubox">
                <span>{selectedMenuItem}</span>
                <div className="dropIcon" onClick={handleMenu}>
                  <RiArrowDropDownLine />
                </div>
              </div>
              {showMenu ? (
                <Styled.MenuList>
                  <ul className="menuListContainer">
                    {menuList?.map((item, index) => {
                      return (
                        <div className="listdata" key={index}>
                          <li
                            onClick={() => handleMenuItemClick(item, index)}
                            className={activeIndex === index ? "active" : ""}
                          >
                            {item?.name}
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                </Styled.MenuList>
              ) : (
                ""
              )}
            </Styled.MenuBox>
          </>
        ) : (
          <div className="menuAndLogo">
            <div>
              <img
                src={appLogo}
                alt="logo"
                className="logoImage"
                onClick={homeNavigation}
              />
            </div>
            <Styled.MenueContainer>
              <ul className="menubox">
                {menuList?.map((item, index) => {
                  console.log(activeIndex === index ? "active" : "inactive");
                  return (
                    <div
                      className={
                        activeIndex === index ? "activeListdata" : "listdata"
                      }
                      key={index}
                    >
                      <li
                        onClick={() => handleMenuItemClick(item, index)}
                        // className={activeIndex === index ? "active" : ""}
                      >
                        {item.name == "Live" ? (
                          <div className="liveTextContainer">
                            <span className="dot"></span> {item?.name}
                          </div>
                        ) : (
                          item.name
                        )}
                      </li>
                    </div>
                  );
                })}
              </ul>
            </Styled.MenueContainer>
          </div>
        )}

        <Styled.RightContainer ref={searchContainerRef}>
          <div>
            {showSearch ? (
              <div className="searchContainer" style={{ height: "45px" }}>
                <Search
                  backGround="#ffffff26"
                  placeholder="Search for titles"
                  onSearch={onSearch}
                />
                <div onClick={handleHide} className="hidesearch">
                  <FaSearch />
                </div>
              </div>
            ) : (
              <div className="searchContainer">
                <div className="hidesearch">
                  <Search
                    backGround="#ffffff26"
                    placeholder="Search for titles"
                  />
                </div>
                <div onClick={handleSearch} className="searchIcon">
                  <FaSearch />
                </div>
              </div>
            )}
          </div>
          {!isPremium && loginUser && (
            <Button className="premiumButton" onClick={navigateSubscribe}>
              Premium
            </Button>
          )}
          {!loginUser ? (
            <div>
              <Button className="btn searchbox" onClick={handleLogin}>
                Login
              </Button>
            </div>
          ) : (
            <div className="userIcon" ref={profileDropdownRef}>
              <div className="profileIcon" onClick={handleProfile}>
                <FaUserCircle />
              </div>
              {showProfileDropdown && (
                <div className="profileDropdown">
                  {profileItems.map((item) => (
                    <div
                      key={item.key}
                      className="profileItem"
                      onClick={() =>
                        handleProfileItemClick(item.key, item.onClick)
                      }
                    >
                      <div className="profileItemIcon">{item.icon}</div>
                      <div className="profileItemLabel">{item.label}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="settingIcon">
            <MdOutlineSettings onClick={handleSettings} />
          </div>
        </Styled.RightContainer>
      </Styled.NavContainer>
    </>
  );
};

export default MenuItems;
