import { Flex } from "antd";
import React from "react";
import { FaPlay } from "react-icons/fa6";
import { RxResume } from "react-icons/rx";

const PlayButtons = ({
  btnColor,
  handleButtonClick,
  status,
  handleResume,
  position,
  details,
}) => {
  return (
    <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
      <button
        className="Detailplay-button"
        style={{ backgroundColor: btnColor }}
        onClick={() => handleButtonClick(details)}
      >
        <FaPlay /> Play
      </button>
      {status === "resume" && (
        <button
          className="Detailplay-button"
          style={{ backgroundColor: btnColor }}
          onClick={() => handleResume(position, details)}
        >
          <RxResume /> Resume
        </button>
      )}
    </div>
  );
};

export default PlayButtons;
