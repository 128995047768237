import { useState, useEffect } from 'react';
import HttpApis from "./../services/HttpApis";
import { message } from "antd";

export const useWatchlist = (authtoken, slug) => {
  const [watchList, setWatchList] = useState();

  const fetchWatchList = async () => {
    const headers = {
      Authorization: `Bearer ${authtoken}`,
      "Content-Type": "application/json",
    };
    const response = await HttpApis.getWatchList("", headers);
    setWatchList(response.data.data);
  };

  const handleAdd = async () => {
    const isAlreadyInFavorites =
      watchList?.movies?.some((item) => item.slug === slug) ||
      watchList?.shows?.some((item) => item.slug === slug);

    if (isAlreadyInFavorites) {
      message.info("Item is already in your favorites");
      return;
    }

    const headers = {
      Authorization: `Bearer ${authtoken}`,
      "Content-Type": "application/json",
    };
    const params = { slug: slug };
    const response = await HttpApis.postAddTofavourite(params, headers);
    if (response.status === 200) {
      fetchWatchList();
      message.success("Item added to favorites");
    } else {
      message.error("Failed to add item to favorites");
    }
  };

  const handleRemove = async () => {
    const headers = {
      Authorization: `Bearer ${authtoken}`,
      "Content-Type": "application/json",
    };
    const params = { slug: slug };
    const response = await HttpApis.postRemovefavourite(params, headers);
    if (response.status === 200) {
      fetchWatchList();
      message.success("Item removed from favorites");
    } else {
      message.error("Failed to remove item from favorites");
    }
  };

  const isInWatchList = watchList?.movies?.some((item) => item.slug === slug) ||
    watchList?.shows?.some((item) => item.slug === slug);

  useEffect(() => {
    if (authtoken) {
      fetchWatchList();
    }
  }, [authtoken]);

  return { watchList, handleAdd, handleRemove, isInWatchList };
};
