import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { btnColor } from "../../theme/color";
import "./DetailHero.css";

import { useWatchlist } from "./../../hooks/useWatchlist";
import { useResponsive } from "./../../hooks/useResponsive";
import HeroBackground from "./HeroBackground";
import HeroContent from "./HeroContent";
import ActionButtons from "./ActionButtons";
import PlayControls from "./PlayControls";
import MobileOptions from "./MobileOptions";
import ShareButton from "./ShareButton";
import { BASE_BACKEND_URL } from "../../api/config";

const DetailHero = ({
  posterUrl,
  title,
  info,
  description,
  details,
  data,
  type,
  handlePlayRoute,
  slug,
  handleDetails,
  status,
  position,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const authtoken = useSelector(
    (state) => state?.authReducer?.user?.data?.token
  );
  const loginUser = useSelector(
    (state) => state?.authReducer?.user?.data?.user
  );

  const { isMobileOrTablet } = useResponsive();
  const { isInWatchList, handleAdd, handleRemove } = useWatchlist(
    authtoken,
    slug
  );

  const handleLogin = () => navigate("/login");

  const handleButtonClick = (details) => {
    if (authtoken) {
      // console.log("Details:", details);
      handlePlayRoute(details);
    } else {
      navigate("/login");
    }
  };

  const handleResume = (position, details) => {
    handlePlayRoute({ ...details, position });
  };

  return (
    <div className="detail-hero">
      <HeroBackground posterUrl={posterUrl}>
        <HeroContent
          title={title}
          info={info}
          description={description}
          details={details} // adding this prop to show languages and categories
        >
          {details && loginUser ? (
            <PlayControls
              isMobileOrTablet={isMobileOrTablet}
              btnColor={btnColor}
              handleButtonClick={handleButtonClick}
              status={status}
              handleResume={handleResume}
              position={position}
              isInWatchList={isInWatchList}
              handleAdd={handleAdd}
              details={details}
              handleRemove={handleRemove}
            />
          ) : (
            <ActionButtons
              loginUser={loginUser}
              handleDetails={handleDetails}
              handleLogin={handleLogin}
              data={data}
              type={type}
            />
          )}

          {isMobileOrTablet && (
            <>
              <div className="mobileSeeMoreButton">
                <button onClick={() => setShowMoreOptions(!showMoreOptions)}>
                  See More
                </button>
              </div>
              {/* See More Button for Mobile */}

              <MobileOptions
                showMoreOptions={showMoreOptions}
                handleAdd={handleAdd}
                handleButtonClick={handleButtonClick}
              >
                <ShareButton BASE_BACKEND_URL={BASE_BACKEND_URL} />
              </MobileOptions>
            </>
          )}
        </HeroContent>
      </HeroBackground>
    </div>
  );
};

export default DetailHero;
