import React from "react";

const HeroBackground = ({ posterUrl, children }) => {
  return (
    <div
      className="Detailhero-background"
      style={{
        backgroundImage: `
          linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 5%, rgba(0, 0, 0, 0)  100%),
          linear-gradient(to right, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 0)90%),
          url(${posterUrl})
        `,
      }}
    >
      {children}
    </div>
  );
};

export default HeroBackground;
