import React, { useState, useEffect } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { MdSubscriptions } from "react-icons/md";
import { useSelector } from "react-redux";
import usePaymentService from "../../hooks/usePaymentService";

const MonetizationButtons = ({
  monetization,
  handleButtonClick,
  details,
  btnColor,
}) => {
  const [loading, setLoading] = useState(false);
  const [paypal, setPaypal] = useState([]);
  const { error, activePaymentMethod, handlePayment, initializePayPalButtons } =
    usePaymentService();

  useEffect(() => {
    initializePayPalButtons(paypal);
  }, [paypal, initializePayPalButtons]);

  useEffect(() => {
    setPaypal([
      {
        plan_id: details?.id,
        productId: details?.id,
        plan_name: details?.title,
        payment_type: "media",
        media_type: details?.type,
        plan_price: details?.monetization.plan_amount,
        currency_code: "USD",
      },
    ]);
  }, [details]);

  const user = useSelector((state) => state?.authReducer?.user.data.user);
  const { monetization_type, plan_amount, plan_period, plan_frequency } =
    monetization;

  const handlePaymentInitiation = async (paymentType) => {
    try {
      setLoading(true);
      await handlePayment({
        payment_type: "media",
        productId: details?.id,
        media_type: details?.type,
      });
    } catch (error) {
      console.error("Payment initiation failed:", error);
      alert("Could not initiate payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleMonetizationClick = async () => {
    switch (monetization_type) {
      case "free":
        // Directly handle free content
        handleButtonClick?.();
        break;

      case "pay_per_view":
        await handlePaymentInitiation("media");
        break;

      case "one_time_pay":
        await handlePaymentInitiation("media");
        break;

      case "subscription_based":
        // Navigate to subscription page or handle subscription flow
        handleButtonClick?.("subscription");
        break;

      default:
        handleButtonClick?.();
    }
  };

  const getPurchaseInfo = () => {
    switch (monetization_type) {
      case "free":
        return {
          mainText: "Watch Free",
          subText: "No Purchase Required",
          icon: <RiMoneyDollarCircleLine />,
        };
      case "pay_per_view":
        return {
          mainText: `Rent for $${plan_amount}`,
          subText: `Valid for ${
            plan_frequency === "minutes"
              ? `${plan_period} minute${plan_period > 1 ? "s" : ""}`
              : plan_frequency === "hour"
              ? `${plan_period} hour${plan_period > 1 ? "s" : ""}`
              : plan_frequency === "day"
              ? `${plan_period} day${plan_period > 1 ? "s" : ""}`
              : plan_frequency === "week"
              ? `${plan_period} week${plan_period > 1 ? "s" : ""}`
              : plan_frequency === "month"
              ? `${plan_period} month${plan_period > 1 ? "s" : ""}`
              : plan_frequency === "year"
              ? `${plan_period} year${plan_period > 1 ? "s" : ""}`
              : ""
          }`,
          icon: <RiMoneyDollarCircleLine />,
        };
      case "one_time_pay":
        return {
          mainText: `Buy for $${plan_amount}`,
          subText: "Lifetime Access",
          icon: <FaShoppingCart />,
        };
      case "subscription_based":
        return {
          mainText: `Subscribe to Watch`,
          subText: "Cancel Anytime",
          icon: <MdSubscriptions />,
        };
      default:
        return {
          mainText: "Watch Now",
          subText: "",
          icon: null,
        };
    }
  };

  const { mainText, subText, icon } = getPurchaseInfo();

  return (
    <div className="flex s gap-2">
      {activePaymentMethod === "paypal" ? (
        <div>
          <div
            id={`paypal-button-${details?.id}`}
            className="paypal-button-container"
          />
          {subText && (
            <span className="text-sm text-center text-gray-500 mt-2">
              {subText}
            </span>
          )}
        </div>
      ) : (
        <>
          <button
            className="Detailplay-button"
            style={{ backgroundColor: btnColor }}
            onClick={handleMonetizationClick}
            disabled={loading}
          >
            <span>{icon}</span> {loading ? "Processing..." : mainText}
          </button>
          {/* {subText && (
            <span className="text-sm text-center text-gray-500">{subText}</span>
          )} */}
        </>
      )}
    </div>
  );
};

export default MonetizationButtons;
