import React, { useEffect, useState } from 'react';
import HttpApis from "../../services/HttpApis";
import { useSelector } from "react-redux";
import './ContinueWatching.css';

function ContinueWatching({ handleDetails }) {
    const [continueWatching, setContinueWatching] = useState([]);
    const authtoken = useSelector((state) => state?.authReducer?.user?.data?.token);
    useEffect(() => {
        const getContinueWatching = async () => {
          const headers = {
            Authorization: `Bearer ${authtoken}`,
            "Content-Type": "application/json",
          };
          const response = await HttpApis.getContinueWatchingList(headers);
          if (response.data.data) {
            setContinueWatching(response.data.data);
        }   
          return (response.data.data);
        };
        getContinueWatching();
      }, []);

    return (
        <div className="grid">
            {continueWatching.map(item => (
                <div key={item.id} className="grid-item">
                    <div className="image-container">
                        <img
                            src={item.media.posterImage}
                            alt={item.name}
                            className="item-image"
                            onClick={()=>handleDetails(item.media.id,item.content_type)}
                        />
                        <div className="progress-bar-container">
                            <div
                                className="progress-bar"
                                style={{ width: `${item.progress_seconds/60}%` }}
                            ></div>
                        </div>
                       
                    </div>
                    <p className="item-name">{item.name}</p>
                </div>
            ))}
        </div>
    );
}


export default ContinueWatching;