import React, { useState, useEffect } from "react";
import Banner from "../Banner/bannner";
import Standard from "../Standard/standard";
import Guide from "../EpgGuide/Guide";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import "./Home_page.css";

const Home_page = ({ selectedSectionID, sectionData, handleDetails }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [slidercount, setSliderCount] = useState(0);

  useEffect(() => {
    if (selectedSectionID?.type === "live") {
      navigateToPlayer(
        selectedSectionID.mediaInfo.type,
        selectedSectionID.mediaInfo.id,
        selectedSectionID.mediaInfo.video_detail.hls_playlist_url
      );
    }
  }, [selectedSectionID, navigate]);

  const navigateToPlayer = (type, id, videoURL) => {
    navigate(`/player/${type}/${id}`, { state: { videoURL } });
  };

  useEffect(() => {
    if (sectionData && sectionData.length > 0) {
      const typeOneData = sectionData.filter(
        (item) => item.type === "Carousel"
      );
      const typeTwoData = sectionData.filter((item) => item.type === "Gallery");
      const newArray = [
        { type: "Carousel", data: typeOneData },
        { type: "Gallery", data: typeTwoData },
      ];
      setData(newArray);

      // Set the slider count
      if (typeOneData.length > 0) {
        setSliderCount(typeOneData[0].items?.length || 0);
      }
    }
  }, [sectionData]);

  const moveLeft = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + slidercount) % slidercount;
      return newIndex < 0 ? slidercount - 1 : newIndex; // Ensure valid index if the result is negative
    });
  };

  // Function to move right in the carousel
  const moveRight = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slidercount);
  };

  const handleSlideChange = (oldIndex, newIndex) => {
    // console.log(newIndex, "sliderIndex");
    setCurrentIndex(newIndex);
  };

  const renderSection = () => {
    if (selectedSectionID?.type === "guide") {
      return <Guide />;
    }

    if (Array.isArray(data)) {
      return data.map((item) => (
        <React.Fragment key={item.type}>
          {item.type === "Carousel" ? (
            <div className="carousel-container">
              {/* Only show buttons if slidercount > 1 */}
              {slidercount > 1 && (
                <Button
                  onClick={moveLeft}
                  className="leftbutton"
                  icon={
                    <FaChevronLeft
                      className="leftRightIcon"
                      style={{ fontSize: "42px" }}
                    />
                  }
                  size="large"
                />
              )}
              <Banner
                selectedSectionID={selectedSectionID}
                sectionData={sectionData}
                handleDetails={handleDetails}
                currentIndex={currentIndex}
                handleSlideChange={handleSlideChange}
              />
              {slidercount > 1 && (
                <Button
                  onClick={moveRight}
                  className="rightbutton"
                  icon={<FaChevronRight style={{ fontSize: "42px" }} />}
                  size="large"
                />
              )}
            </div>
          ) : (
            <div className="StandardContainer">
              <Standard
                sectionData={sectionData}
                handleDetails={handleDetails}
              />
            </div>
          )}
        </React.Fragment>
      ));
    }

    return null;
  };

  return (
    <div
    // style={{ background: "#191919" }}
    >
      {renderSection()}
    </div>
  );
};

export default Home_page;
