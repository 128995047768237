import React, { useState, useEffect } from "react";
import { Epg, Layout } from "planby";
import { useApp } from "../../hooks/ChannelsHook";
import { ProgramItem } from "./ProgramItem";
import { Timeline } from "./Timeline";
import { ChannelItem } from "./channels";
import "./epg.css"
import Hero from "../Hero/Hero";
import { useNavigate } from "react-router-dom";


function Guide() {

  
  const { isLoading, getEpgProps, getLayoutProps } = useApp();
  const [infodta, setInfodta] = useState({});
  const [duration, setDuration] = useState(null);
  const navigate = useNavigate();
  const navigateToPlayer = (type, id, videoURL) => {
    console.log("playlist_url",videoURL,type);
    navigate(`/player/${type}/${id}`, { state: { videoURL } });
   
  };
  // const navigateToPlayer = (type, id, videoURL) => {
  //   console.log("type", type);
  //   navigate(`/player/${type}/${id}`, { state: { videoURL } });
  // };

  const handleProgramClick = (data) => {
    console.log("data",data.data);
    navigateToPlayer( "live",1,data.data.playlist_url);

    
    
    
  };

  useEffect(() => {

    if (infodta.since && infodta.till) {
      const calculateDuration = () => {
        const sinceDate = new Date(infodta.since);
        const tillDate = new Date(infodta.till);
        const durationInMillis = tillDate - sinceDate;
        const durationInMinutes = Math.floor(durationInMillis / 1000 / 60);
        setDuration(durationInMinutes);
      };

      calculateDuration();
    }
  }, [infodta]);

  return (
    <div >
      <div style={{ height: "100vh", width: "100%", background:'black' }}>
        <Epg isLoading={isLoading} {...getEpgProps()}> 
          <Layout  style={{backgroundColor:'black'}}
            {...getLayoutProps()}
            renderTimeline={(props) => <Timeline {...props} />}
            renderProgram={({ program, ...rest }) => (
              <ProgramItem key={program.data.id} program={program} {...rest} setInfodta={setInfodta}  style = {{backgroundColor:'white  '}}  onClick={() => handleProgramClick(program)}/>
            )} 
            renderChannel={({ channel }) => (
              <ChannelItem key={channel.uuid} channel={channel} />
            )}
          />
        </Epg>
      </div>
    </div>
  );
}

export default Guide;