import React, { useState } from 'react';
import { message } from "antd";
import ShareModal from './ShareModal';
import { IoMdShare } from "react-icons/io";

const ShareButton = ({ BASE_BACKEND_URL }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showLoading = () => {
    setOpen(true);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleCopy = () => {
    const link = `${BASE_BACKEND_URL}`;
    const tempInput = document.createElement("input");
    tempInput.value = link;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    message.success("Link copied to clipboard");
  };

  return (
    <>
      <div onClick={showLoading}>
        <IoMdShare className="subtractIcon" />
        Share
      </div>
      <ShareModal
        open={open}
        setOpen={setOpen}
        handleCopy={handleCopy}
        BASE_BACKEND_URL={BASE_BACKEND_URL}
        loading={loading}
      />
    </>
  );
};

export default ShareButton;