// PlayControls.js
import React from "react";
import PlayButtons from "./PlayButtons";
import FavoriteButton from "./FavoriteButton";
import MonetizationButtons from "./MonetizationButtons";

const PlayControls = ({
  isMobileOrTablet,
  btnColor,
  handleButtonClick,
  status,
  handleResume,
  position,
  isInWatchList,
  handleAdd,
  handleRemove,
  details,
}) => {
  const { monetization, access_info } = details || {};

  return (
    <div className="flex flex-col gap-8">
      {access_info?.has_access ? (
        <PlayButtons
          btnColor={btnColor}
          handleButtonClick={() => handleButtonClick(details)}
          status={status}
          handleResume={(position) => handleResume(position, details)}
          position={position}
          details={details}
        />
      ) : (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <MonetizationButtons
            monetization={monetization}
            handleButtonClick={() => handleButtonClick(details)}
            details={details}
            btnColor={btnColor}
          />
          {!isMobileOrTablet && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "10px",
              }}
            >
              <FavoriteButton
                isInWatchList={isInWatchList}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlayControls;
