import React from 'react';
import { btnColor } from "../../theme/color";

const ActionButtons = ({ loginUser, handleDetails, handleLogin, data, type }) => {
  if (!loginUser) {
    return (
      <button
        className="Detailplay-button"
        style={{ backgroundColor: btnColor }}
        onClick={handleLogin}
      >
        Login
      </button>
    );
  }

  return (
    <button
      className="Detailplay-button"
      style={{ backgroundColor: btnColor }}
      onClick={() => handleDetails(data, type)}
    >
      Details
    </button>
  );
};

export default ActionButtons;
